<template>
  <div class="px-2"></div>
    <div class="card desc-card mb-2 mx-3" v-for="order in orderDetails" :key="order.id" style="height: auto;">
      <div v-if="order.paymentMode=='COD'">
      <h1  class="text-center fw-bold">COD - Rs. {{order.codAmount}}</h1>
      <hr/>
      </div>
      <h6 class="text-muted">{{order.paymentMode}} ORDER#: {{order.orderCode}}</h6>
      <h6><b>TO:</b> {{order.deliveryAddress.name}}</h6>
      <h6><b>MOBILE:</b> +91-{{order.deliveryAddress.mobile}}</h6>
      <h6><b>ADDRESS:</b> {{order.deliveryAddress.deliveryAddress}}</h6>
      <h6 class="mt-3" v-if="order.deliveryAddress.fromName"><b>FROM:</b> {{order.deliveryAddress.fromName}}</h6>
      <h6 class="mt-3" v-else><b>FROM:</b> {{order.resellerName}}</h6>
      <p class="text-muted mb-0"><b>Product details:</b></p>
      <div v-for="item in order.orderItem" :key="item.id">
        <p class="text-muted m-0">{{item.productCode}} (<span v-if="item.color">{{item.color}}&nbsp;</span>{{item.itemSize}}) * {{item.qty}}</p>
      </div>
    </div>
</template>

<script>

import { useRoute } from 'vue-router'
import { getLoggedInUser } from '@/storage/auth.module'
import api from '@/storage/api'

export default {
  name: 'OrderPrint',
  data () {
    return {
      orderDetails: [],

      userToken: getLoggedInUser().userToken
    }
  },
  created () {
    const route = useRoute()
    // fetch(`http://localhost:8080/orders/print/0/100?uuid=${this.userToken}`)
    api.get(`/orders/READY/0/100?uuid=${this.userToken}`)
      .then(response => response.data)
      .then(data => {
        // this.orderDetails = data.content
        this.orderDetails = data.content.filter(order => order.orderStatus === 'READY')
      })
      .catch(error => console.error(error))
  }
}
</script>

<style scoped>
h6 {
  font-weight: 700;
  text-transform: none;
}
</style>
