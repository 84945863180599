import { DateTime } from 'luxon'

export function timeSinceLastItemUpdated (lastItemUpdatedDate) {
  if (!lastItemUpdatedDate) {
    return ''
  }
  // Calculate the time since the last item was added
  const lastAddedDate = DateTime.fromISO(lastItemUpdatedDate)
  const now = DateTime.local()
  const diff = now.diff(lastAddedDate, ['years', 'months', 'days', 'hours', 'minutes', 'seconds']).toObject()
  const years = diff.years ?? 0 // Use nullish coalescing operator to provide default value
  const months = diff.months ?? 0 // Use nullish coalescing operator to provide default value
  const days = diff.days ?? 0 // Use nullish coalescing operator to provide default value
  const hours = diff.hours ?? 0 // Use nullish coalescing operator to provide default value
  const minutes = diff.minutes ?? 0 // Use nullish coalescing operator to provide default value
  if (years > 0) {
    return `${years} yr${years > 1 ? 's' : ''} ago`
  } else if (months > 0) {
    return `${months} month${months > 1 ? 's' : ''} ago`
  } else if (days > 0) {
    return `${days} day${days > 1 ? 's' : ''} ago`
  } else if (hours > 0) {
    return `${hours} hr${hours > 1 ? 's' : ''} ago`
  } else if (minutes > 0) {
    return `${minutes} min${minutes > 1 ? 's' : ''} ago`
  } else {
    return 'just now'
  }
}
