import router from '@/router'
export const setUser = (userId: string, userRole: string, userToken: string) => {
  localStorage.setItem('userId', userId)
  localStorage.setItem('userRole', userRole)
  localStorage.setItem('userToken', userToken)
  // router.push({ path: '/home' })
}
export const setUserMobile = (mobile: string) => {
  localStorage.setItem('mobile', mobile)
}
export const logoutUser = () => {
  localStorage.removeItem('userId')
  localStorage.removeItem('userRole')
  localStorage.removeItem('userToken')
  // router.push({ path: '/login' })
  router.push({ path: '/login', query: { utm_source: 'logout' } })
}
export const getLoggedInUser = () => {
  const userId = localStorage.getItem('userId')
  const userRole = localStorage.getItem('userRole')
  const userToken = localStorage.getItem('userToken')
  return { userId, userRole, userToken }
}
export const getLoggedInUserMobile = () => {
  const mobile = localStorage.getItem('mobile')
  return { mobile }
}
