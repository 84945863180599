<template>
  <div class="container text-center mt-5">
    <img src="../../assets/static/media/ghost.gif" style="height:200px;">
    <h1>404 - Page Not Found</h1>
    <p>This page no longer exists or was moved to another location.</p>
    <router-link class="btn-secondary btn" to="/home">Home</router-link>
  </div>
</template>

<style scoped lang="scss">
</style>
