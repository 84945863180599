export default {
  state: {
    favorites: []
  },
  mutations: {
    UPDATE_FAVORITES (state:any, payload:any) {
      state.favorites = payload
    }
  },
  actions: {
    addToFavorites (context: any, payload: any) {
      const favorites = context.state.favorites
      favorites.push(payload)
      context.commit('UPDATE_FAVORITES', favorites)
    }
  }
}
