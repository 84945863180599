<template>
  <app-header title="Delivery address" showBackBtn/>
  <div class="mt-1">&nbsp;</div>
  <div class="mt-5">
<!--        <p class="text-muted">{{ userId }} {{ userRole }} {{ userToken }}</p>-->
    <form class="container mt-2" @submit.prevent="addContact">
      <div class="form-floating">
        <input type="text" :class="{'invalid': !isNameValid}" inputmode="text" class="form-control" placeholder="Name" id="name" v-model="name" required>
        <label for="name">Name</label>
      </div>
      <div class="row mb-2"></div>
      <div class="form-floating">
        <input type="number" pattern="[0-9]*" :class="{'invalid': !isPhoneValid}" inputmode="numeric" class="form-control" placeholder="Mobile" id="phone" v-model="mobile" required>
        <label for="name">Mobile</label>
      </div>
      <div class="row mb-2"></div>
      <div class="form-floating">
        <textarea style="height:100px;" type="text" :class="{'invalid': !isAddressValid}" inputmode="text" class="form-control" placeholder="Delivery address" id="address" v-model="address" required />
        <label for="name">Delivery address</label>
      </div>
      <div class="row mb-2"></div>
      <div class="form-floating">
        <input type="number" pattern="[0-9]*" :class="{'invalid': !isPostalCodeValid}" inputmode="numeric" class="form-control" placeholder="pincode"  id="postal-code" v-model="zipcode" required>
        <label for="name">Pincode</label>
      </div>
      <div class="row mb-2"></div>
      <div class="form-floating">
        <input type="text" inputmode="text" class="form-control" placeholder="fromName" id="fromname" v-model="fromName">
        <label for="name">Deliver From</label>
      </div>
      <div class="row mb-2"></div>

      <div class="fixed-bottom">
<!--        <div class="mt-2">-->
<!--          <div class="card-footer card-footer-info" style="text-align: left;background: #FFF5D9;font-weight: 600;font-size: 12px;">-->
<!--            📣 Once you add, go back to select the contact from the dropdown.-->
<!--          </div>-->
<!--        </div>-->
      <nav class="navbar navbar-light bg-light btn-nav">
        <button v-if="disableAddBtn" class="btn btn-primary w-100" disabled><img src="../../assets/static/media/dizzy.gif" class="align-bottom" style="height:20px;"></button>
        <button v-else type="submit" :disabled="!isFormValid" class="btn btn-primary w-100">Add customer</button>
      </nav>
      </div>
    </form>
    <ul>
      <li v-for="contact in contacts" :key="contact.phone">
        {{ contact.name }} - {{ contact.phone }} - {{ contact.address }} - {{ contact.postalCode }}
      </li>
    </ul>
  </div>
</template>

<script>
import AppHeader from '@/components/AppHeader.vue'
import router from '@/router'
import { getLoggedInUser } from '@/storage/auth.module'
import api from '@/storage/api'
import { toast } from 'vue3-toastify'
import Mixpanel from 'mixpanel-browser'

export default {
  name: 'Contact',
  components: {
    AppHeader
  },
  data () {
    return {
      name: '',
      fromName: '',
      mobile: '',
      address: '',
      zipcode: '',
      contacts: [],
      userId: getLoggedInUser().userId,
      userRole: getLoggedInUser().userRole,
      userToken: getLoggedInUser().userToken,
      errorMessage: '',
      disableAddBtn: false
    }
  },
  computed: {
    isNameValid () {
      // return /^[a-zA-Z\s]*$/.test(this.name)
      return this.name.length > 0
    },
    isPhoneValid () {
      return /^[0-9]{10}$/.test(this.mobile)
    },
    isAddressValid () {
      return this.address.length > 0
    },
    isPostalCodeValid () {
      return /^[0-9]{6}$/.test(this.zipcode)
    },
    isFormValid () {
      return this.isNameValid && this.isPhoneValid && this.isAddressValid && this.isPostalCodeValid
    }
  },
  methods: {
    addContact () {
      this.disableAddBtn = true
      this.contact = {
        name: this.name,
        mobile: this.mobile,
        address: this.address,
        zipcode: this.zipcode,
        fromName: this.fromName
      }
      api.post(`/contacts?uuid=${this.userToken}`, {
        ...this.contact
      })
        .then(response => {
          console.log(response)
          if (response.status === 200) {
            this.name = ''
            this.mobile = ''
            this.address = ''
            this.zipcode = ''
            this.fromName = ''
            router.push({ path: '/cart', query: { contactId: response.data.id } })
            toast.success('Contact added successfully!')
            Mixpanel.track('Contact added', {
              status: 'Success',
              contactId: response.data.id
            })
          } else {
            this.disableAddBtn = false
            this.errorMessage = 'An error occurred while adding the item.'
            toast.error(this.errorMessage)
            Mixpanel.track('Contact creation failed', { status: 'Failure', errorMessage: this.errorMessage })
          }
        })
        .catch(error => {
          this.disableAddBtn = false
          this.errorMessage = error.message
          toast.error(this.errorMessage)
          Mixpanel.track('Contact creation failed', { status: 'Failure', errorCode: error.code, errorMessage: error.message })
        })
    }
  }
}
</script>

<style scoped>

</style>

<!--async addContact () {-->
<!--this.contact = {-->
<!--name: this.name,-->
<!--mobile: this.mobile,-->
<!--address: this.address,-->
<!--zipcode: this.zipcode-->
<!--}-->
<!--const url = 'http://localhost:8080/contacts?uuid=leh4ejay'-->
<!--try {-->
<!--const response = await fetch(url, {-->
<!--method: 'POST',-->
<!--headers: {-->
<!--'Content-Type': 'application/json'-->
<!--},-->
<!--body: JSON.stringify(this.contact)-->
<!--})-->
<!--if (!response.ok) {-->
<!--this.isShowErrorToast = true-->
<!--this.errorMessage = 'An error occurred while adding the item.'-->
<!--throw new Error('Failed to add contact')-->
<!--}-->
<!--// handle success response-->
<!--console.log('Contact added successfully!')-->
<!--this.isShowSuccessToast = true-->
<!--this.name = ''-->
<!--this.mobile = ''-->
<!--this.address = ''-->
<!--this.zipcode = ''-->
<!--} catch (error) {-->
<!--// handle error response-->
<!--console.error(error.message)-->
<!--this.isShowErrorToast = true-->
<!--this.errorMessage = error.message-->
<!--}-->
<!--}-->
<!--this.name = ''-->
<!--this.mobile = ''-->
<!--this.address = ''-->
<!--this.zipcode = ''-->
<!--router.push({ name: 'cart' })-->
<!--this.contacts.push({-->
<!--name: this.name,-->
<!--phone: this.mobile,-->
<!--address: this.address,-->
<!--postalCode: this.zipcode-->
<!--})-->
