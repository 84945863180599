<template>
  <footer class="footer px-3">
    <p class="mt-2 mb-0 text-muted">Powered by</p>
    <p class="mb-0" style="font-weight: 600;">eSetu</p>
    <p style="font-size:12px;">Connecting business and customer experience</p>
    <p class="text-muted">Copyright &copy; {{ year }}</p>
    <p class="text-muted">By accessing our platform, you agree to our <a href="/terms">terms and conditions</a>.</p>
    <p class="text-muted" style="opacity: 0;"><a href="/push">push</a></p>
  </footer>
</template>
<script>
import { computed } from 'vue'

export default {
  setup () {
    const year = computed(() => new Date().getFullYear())
    return { year }
  }
}
</script>
