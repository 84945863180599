<template>
  <splash-screen v-if="loading" />
  <app-header v-if="userRole=='CUSTOMER'" :wa-number="orderMeta.store.waNumber" :mobile="orderMeta.store.mobile" :title="'#'+$route.params.orderId" :wa-msg="'Order #'+$route.params.orderId+', my query is: '" showBackBtn showContactCta/>
  <app-header v-else :wa-number="orderMeta.resellerWaNumber" :mobile="orderMeta.resellerWaNumber" :title="'#'+$route.params.orderId" :wa-msg="'Order #'+$route.params.orderId+', my response is: '" showBackBtn showContactCta/>

  <!--  {{displayedProducts}}-->
<!--    <p class="my-header px-3 mt-3">selected items</p>-->
  <div class="mt-1">&nbsp;</div>
  <div class="text-center mt-5" v-if="loading">
      <p  class="text-black-50">Loading...
        <img src="../../assets/static/media/dizzy.gif" class="align-bottom" style="height:20px;">
      </p>
    </div>
    <div v-else id="myDIV" class="modal-open==false">
  <div class="d-flex justify-content-between mt-5">
    <p class="my-header">Selected items</p>
<!--    <a v-if="userRole!=='CUSTOMER'" class="card-action-link mx-3" href="#"  data-bs-toggle="modal" data-bs-target="#exampleModal">change status</a>-->
    <a v-if="userRole!=='CUSTOMER'" class="card-action-link mx-3" href="#" @click="showStatusModalBox()">change status</a>
  </div>
<!--      <p class="text-muted">{{ userId }} {{ userRole }} {{ userToken }}</p>-->
  <div class="card product-card mb-3 mx-3" v-for="item in displayedProducts" :key="item.id" style="height: 130px;">
    <div class="d-flex justify-content-between">
      <div class="col-8">
        <img :alt="item.productCode" :src=item.img>
        <h5 class="card-title mb-2" style="font-weight: 400;color:#767676;font-size:12px;">ItemID: {{ item.productCode }}</h5>
        <p v-if="item.color" class="card-text mb-2">Color: {{ getColorDisplayName(item.color) }} <span class="badge" :style="'background:'+ getColorCode(item.color)" style="color:#2d2d2d;height: 10px;width: 10px;">&nbsp;</span></p>
        <p class="card-text">Size: {{ item.itemSize }} | Qty: {{ item.qty }}</p>
        <h6 class="card-subtitle">Rs. {{ item.price }}</h6>
      </div>
      <div class="col-2">
          <span class="float-end" style="font-weight: 500;font-size: 14px">
            Rs. {{ item.price * item.qty }}
          </span>
      </div>
    </div>
  </div>
  <div v-show="orderDetails.length>1" class="px-2 mb-3 text-center">
    <button class="btn btn-outline-primary" style="border: none;" v-if="!showAllProducts" @click="showAllProducts = true"> + More
      {{ orderDetails.length-1 }} items</button>
    <button class="btn btn-outline-primary" style="border: none;" v-if="showAllProducts" @click="showAllProducts = false">Hide items</button>
  </div>
  <div class="container mb-2" v-if="orderMeta.logistics && (orderMeta.orderStatus == 'COMPLETED' || orderMeta.orderStatus == 'SETTLED')">
    <div class="card desc-card tracker-card">
<!--      <h5 class="card-title-uppercase mb-3">Logistics details</h5>-->
      <div class="d-flex justify-content-between">
        <h5 class="card-title-uppercase mb-3">Logistics details</h5>
        <a :href=link+orderMeta.awb target=’_blank’ class="card-action-link">Track</a>
<!--        <button @click="getLink('ANJANI')">Get Link</button>-->
<!--        <p v-if="link">The link for {{ abbreviation }} is: {{ link }}</p>-->
      </div>
      <div class="d-flex justify-content-between mb-1">
        <h5 class="inactive-text">Courier partner</h5>
        <h5 class="active-text">{{ orderMeta.logistics }}</h5>
      </div>
      <div class="d-flex justify-content-between mb-1">
        <h5 class="inactive-text">AWB</h5>
        <h5 class="active-text">{{ orderMeta.awb }}</h5>
      </div>
      </div>
    </div>
  <div class="container mb-2">
      <div class="card desc-card">
          <div class="d-flex justify-content-between">
            <h5 class="card-title-uppercase mb-3">Delivery address</h5>
          </div>
          <h6 class="card-subtitle mb-2">{{ orderMeta.deliveryAddress.name }} <a :href="'tel:+' + orderMeta.deliveryAddress.mobile">+91 {{orderMeta.deliveryAddress.mobile}}</a> <span class="card-text" v-if="orderMeta.deliveryAddress.fromName">| From: {{orderMeta.deliveryAddress.fromName}}</span></h6>
          <p class="card-text text-muted">{{ orderMeta.deliveryAddress.deliveryAddress }}</p>
      </div>
  </div>

  <div class="container mb-2">
    <div class="card desc-card tracker-card">
      <h5 class="card-title-uppercase mb-3">Order details</h5>
      <div class="d-flex justify-content-between mb-1">
        <h5 class="inactive-text">Order ID</h5>
        <h5 class="active-text">{{ orderMeta.orderCode }}</h5>
      </div>
      <div class="d-flex justify-content-between mb-1">
        <h5 class="inactive-text">Buyer</h5>
        <h5 class="active-text">{{ orderMeta.resellerName }}</h5>
      </div>
      <div class="d-flex justify-content-between mb-1">
        <h5 class="inactive-text">Seller</h5>
        <h5 class="active-text">{{orderMeta.store.displayName}}</h5>
      </div>
      <div class="d-flex justify-content-between mb-1">
        <h5 class="inactive-text">Order date & time</h5>
        <h5 class="active-text">{{ formattedDate(orderMeta.orderedOn) }}</h5>
      </div>
      <div class="d-flex justify-content-between mb-1">
        <h5 class="inactive-text">Order Status</h5>
        <h5 class="active-text">{{ orderMeta.orderStatusDisplayName }}</h5>
      </div>
      <div class="d-flex justify-content-between mb-1">
        <h5 class="inactive-text">Payment Mode</h5>
        <h5 class="active-text">{{ orderMeta.paymentMode }}
        </h5>
      </div>
      <div class="d-flex justify-content-between mb-1" v-if="orderMeta.prepaidPaymentMode" >
        <h5 class="inactive-text">Prepaid payment Mode</h5>
        <h5 class="active-text">{{ orderMeta.prepaidPaymentMode }}</h5>
      </div>
    </div></div>
      <div class="container mb-3">
        <div class="card desc-card tracker-card">
          <h5 class="card-title-uppercase mb-3">Payment details</h5>
      <div class="d-flex justify-content-between mb-1" v-if="orderMeta.codAmount!==0">
        <h5 class="inactive-text">Customer will pay</h5>
        <h5 class="active-text">+ Rs. {{ orderMeta.codAmount }}</h5>
      </div>
      <div class="d-flex justify-content-between mb-1" v-if="orderMeta.paymentMode=='COD'">
        <h5 class="inactive-text">We will deduct to pay to supplier</h5>
        <h5 class="active-text">- Rs. {{ orderMeta.orderValue }}</h5>
      </div>
      <div class="d-flex justify-content-between mb-1" v-if="orderMeta.paymentMode!=='COD'">
        <h5 class="inactive-text">Order value</h5>
        <h5 class="active-text">+ Rs. {{ orderMeta.orderValue }}</h5>
      </div>
      <div class="d-flex justify-content-between mb-1" v-if="orderMeta.paymentMode=='COD'">
        <h5 class="inactive-text">COD advance Fees collected</h5>
        <h5 class="active-text">+ Rs. {{ orderMeta.codFees }}</h5>
      </div>
      <div class="d-flex justify-content-between mb-1" v-if="orderMeta.paymentMode!=='COD'">
        <h5 class="inactive-text">Prepaid order discount</h5>
        <h5 class="active-text">- Rs. {{ orderMeta.paymentModeDiscount }}</h5>
      </div>
      <div class="d-flex justify-content-between mb-1" v-if="orderMeta.paymentMode!=='COD'">
        <h5 class="inactive-text">Coupon discount</h5>
        <h5 class="active-text">- Rs. {{ orderMeta.couponDiscount }}</h5>
      </div>
      <div class="d-flex justify-content-between mb-1" v-if="orderMeta.discount > 0">
        <h5 class="active-text text-primary" style="font-weight: 700;">Total discount</h5>
        <h5 class="active-text text-primary" style="font-weight: 700;">- Rs. {{ orderMeta.discount }}</h5>
      </div>
      <div class="d-flex justify-content-between mb-1" v-if="orderMeta.paymentMode!=='COD'">
        <h5 class="inactive-text">Total payable</h5>
        <h5 class="active-text">Rs. {{ orderMeta.payableAmount }}</h5>
      </div>
      <div class="d-flex justify-content-between mb-1" v-if="orderMeta.paymentMode=='COD'">
        <h5 class="active-text text-primary" style="font-weight: 700;">Margin you will earn</h5>
        <h5 class="text-primary" style="font-weight: 700;">Rs. {{ orderMeta.codMargin }}</h5>
      </div>
      <div class="mt-2" v-if="orderMeta.paymentMode=='COD'">
        <div class="card-footer card-footer-info" style="text-align: left;background: #FFF5D9;font-weight: 600;font-size: 12px;border-radius: 20px;">
          💰 Your earning will be credited to wallet safely and securely.
        </div>
      </div>
    </div>
  </div>
    </div>
  <!-- Modal -->

  <div v-show="showStatusModal">
    <div class="modal" tabindex="-1" role="dialog" ref="modal">
<!--  <div class="modal"  id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">-->
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Change Status #{{orderMeta.orderCode}}</h5>
<!--          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>-->
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="hideStatusModalBox"></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="updateOrder(orderMeta.orderId)">
            <div class="form-floating mb-3">
              <select v-model="orderStatus" class="form-select" id="status" aria-label="Floating label select example" required>
                <option selected value="">Select</option>
<!--                <option value="ACCEPTED">ACCEPTED</option>-->
                <option value="READY">Ready to dispatch</option>
                <option value="COMPLETED">Dispatched</option>
                <option value="REJECTED">Cancelled</option>
                <option value="SETTLED" v-if="userRole=='ADMIN'">Delivered</option>
              </select>
              <label for="floatingSelect">Status</label>
            </div>
            <div class="form-floating mb-3" v-if="orderStatus=='COMPLETED'">
              <select v-model="logistics" class="form-select" id="logistics" aria-label="Floating label select example" required>
                <option selected value="">Select</option>
                <option value="TIRUPATI">TIRUPATI</option>
                <option value="ANJANI">ANJANI</option>
                <option value="FRANCH">FRANCH</option>
                <option value="TRACKON">TRACKON</option>
                <option value="AIRWAYS">AIRWAYS</option>
                <option value="DELHIVERY">DELHIVERY</option>
                <option value="DTDC">DTDC</option>
                <option value="BLUEDART">BLUEDART</option>
                <option value="XPRESSBEES">XPRESSBEES</option>
              </select>
              <label for="floatingSelect">Logistics</label>
            </div>
            <div class="form-floating mb-1" v-if="orderStatus=='COMPLETED'">
              <input v-model="awb" type="text" class="form-control" placeholder="AWB" id="awb" required>
              <label for="awb">AWB</label>
            </div>
            <div class="modal-footer">
              <button v-if="orderMeta.orderStatus=='SETTLED'" disabled type="submit" class="btn btn-primary col-12">SETTLED ORDER</button>
              <button v-else-if="orderMeta.orderStatus=='REJECTED'" disabled type="submit" class="btn btn-primary col-12">REJECTED ORDER</button>
              <button v-else type="submit" class="btn btn-primary col-12">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  </div>
  <app-footer class="mt-1"/>
  <!-- Button trigger modal -->
<!--  <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">-->
<!--    User Login-->
<!--  </button>-->
<!--  <nav class="navbar fixed-bottom navbar-light bg-light btn-nav">-->
<!--    <button @click="changeStatus(orderMeta.id)"  data-bs-toggle="modal" data-bs-target="#exampleModal" type="submit" class="btn btn-primary w-100">Change order status</button>-->
<!--  </nav>-->
</template>

<script>
import { useRoute } from 'vue-router'
import AppHeader from '@/components/AppHeader.vue'
import router from '@/router'
import logistics from '@/data/logistics'
import { computed } from 'vue'
import stores from '@/data/stores'
import axios from 'axios'
import { getLoggedInUser } from '@/storage/auth.module'
import api from '@/storage/api'
import SplashScreen from '@/components/SplashScreen.vue'
import Mixpanel from 'mixpanel-browser'
import color from '@/data/color'
import AppFooter from '@/components/AppFooter.vue'
Mixpanel.init(process.env.VUE_APP_MIXPANEL_TOKEN)
export default {
  components: {
    AppFooter,
    AppHeader,
    SplashScreen
  },
  props: {
    orderId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      orderDetails: [],
      orderMeta: [],
      showAllProducts: false,
      showModal: false,
      loading: true,
      abbreviation: '',
      link: '',
      orderStatus: '',
      logistics: 'TBD',
      awb: '',
      orderUpdated: false,
      userId: getLoggedInUser().userId,
      userRole: getLoggedInUser().userRole,
      userToken: getLoggedInUser().userToken,
      showStatusModal: false
    }
  },
  mounted () {
    Mixpanel.track('Order Detail Page Viewed', {
      orderId: this.$route.params.orderId
    })
    // Add a listener to the popstate event
    // window.addEventListener('popstate', () => {
    //   this.$router.push({ name: 'order' })
    // })
  },
  computed: {
    displayedProducts () {
      if (this.showAllProducts) {
        return this.orderDetails
      } else {
        return [this.orderDetails[0]]
      }
    }
  },
  created () {
    if (this.$route && this.$route.params) {
      this.viewOrder(this.$route.params.orderId)
    }
  },
  methods: {
    getColorCode (colorName) {
      const selectedColor = color.find(color => color.value === colorName)
      return selectedColor.code
    },
    getColorDisplayName (colorName) {
      const selectedColor = color.find(color => color.value === colorName)
      return selectedColor.name
    },
    showStatusModalBox () {
      this.showStatusModal = true
      this.$refs.modal.classList.add('show')
      this.$refs.modal.style.display = 'block'
      const myDiv = document.getElementById('myDIV')
      myDiv.classList.add('modal-open')
    },
    hideStatusModalBox () {
      this.showStatusModal = false
      this.$refs.modal.classList.remove('show')
      this.$refs.modal.style.display = 'none'
      const myDiv = document.getElementById('myDIV')
      myDiv.classList.remove('modal-open')
    },
    viewOrder (orderId) {
      // const route = useRoute()
      api.get(`/orders/search/${orderId}?uuid=${this.userToken}`)
        .then(response => response.data)
        .then(data => {
          this.orderDetails = data.orderItem
          this.orderMeta = data
          if (data.logistics) {
            this.getLink(data.logistics)
          }
          this.loading = false
        })
        .catch(error => console.error(error))
    },
    updateOrder (orderId) {
      const data = {
        orderStatus: this.orderStatus,
        logistics: this.logistics,
        awb: this.awb
      }
      // const uuid = 'leh4ejay'
      // axios.put(`http://localhost:8080/orders/${orderId}?uuid=${uuid}`, data)
      api.put(`/orders/status/${orderId}?uuid=${this.userToken}`, data)
        .then(response => {
          this.hideStatusModalBox()
          this.viewOrder(response.data.code)
        })
        .catch(error => {
          console.log(error)
        })
    },
    changeStatus (orderId) {
      alert(orderId)
    },
    openModal () {
      this.showModal = true
    },
    getLink (abbreviation) {
      const linkObj = logistics.find(obj => obj.abbreviation === abbreviation.toUpperCase())
      if (linkObj) {
        this.link = linkObj.link
      } else {
        this.link = ''
      }
    },
    formattedDate (timestamp) {
      const date = new Date(timestamp)
      return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
    }
  }
}
</script>

<style scoped>
.modal-dialog {
  position: fixed;
  bottom: 0;
  left: 0%;
  right: 0%;
  transform: translate(-50%, -50%);
}
.modal-open {
  filter: grayscale(100%) opacity(30%) blur(4px);
}
</style>
