<template>
  <app-header title="Bank Transfer Request" showBackBtn/>
<div class="mt-5">&nbsp;</div>
  <div class="container mb-5">
    <div class="card desc-card tracker-card">
      <h5 class="card-title-uppercase mb-3">Request details</h5>
      <div class="d-flex justify-content-between mb-1">
        <h5 class="inactive-text">Raised by</h5>
        <h5 class="active-text">{{ requestDetails.requesterName }}</h5>
      </div>
      <div class="d-flex justify-content-between mb-1">
        <h5 class="inactive-text">Raised by</h5>
        <h5 class="active-text"><a :href="'Tel:+'+requestDetails.requesterMobile">{{ requestDetails.requesterMobile }}</a></h5>
      </div>
      <div class="d-flex justify-content-between mb-1">
        <h5 class="inactive-text">Request# </h5>
        <h5 class="active-text">{{ requestDetails.code }}</h5>
      </div>
      <div class="d-flex justify-content-between mb-1">
        <h5 class="inactive-text">Request date</h5>
        <h5 class="active-text">{{ formattedDate(requestDetails.createdDate) }}</h5>
      </div>
      <div class="d-flex justify-content-between mb-1">
        <h5 class="inactive-text">Status</h5>
        <h5 class="active-text">{{ requestDetails.walletRedeemRequestStatus }}</h5>
      </div>
      <div class="d-flex justify-content-between mb-1">
        <h5 class="inactive-text">Amount</h5>
        <h5 class="active-text">Rs. {{ requestDetails.amount }}</h5>
      </div>
      <div class="d-flex justify-content-between mb-1">
        <h5 class="inactive-text">Transfer to (UPI ID)</h5>
        <h5 class="active-text">{{ requestDetails.toVpa }}</h5>
      </div>
      <div class="d-flex justify-content-between mb-1" v-if="requestDetails.fromVpa">
        <h5 class="inactive-text">Transfer from (UPI ID)</h5>
        <h5 class="active-text">{{ requestDetails.fromVpa }}</h5>
      </div>
      <div class="d-flex justify-content-between mb-1" v-if="requestDetails.utr">
        <h5 class="inactive-text">Transaction#</h5>
        <h5 class="active-text">{{ requestDetails.utr }}</h5>
      </div>
      <div class="d-flex justify-content-between mb-1" v-if="requestDetails.transferDate">
        <h5 class="inactive-text">Transfer date</h5>
        <h5 class="active-text">{{ formattedDate(requestDetails.transferDate) }}</h5>
      </div>
      <div class="mt-2">
        <div class="card-footer card-footer-info" style="text-align: left;background: #FFF5D9;font-weight: 600;font-size: 12px;border-radius: 20px;">
          💰 Your earning will be credited to wallet safely and securely.
        </div>
      </div>
    </div>
  </div>
  <form @submit.prevent="submitRequest" class="container mt-2" v-if="userRole=='ADMIN' && requestDetails.walletRedeemRequestStatus=='PENDING'">
    <div class="form-floating mb-3">
      <select v-model="requestStatus" class="form-select" id="requestStatus" aria-label="Floating label select example" required>
        <option selected value="">Select</option>
        <option value="APPROVED">APPROVED</option>
        <option value="REJECTED">REJECTED</option>
      </select>
      <label for="floatingSelect">Status</label>
    </div>
    <div v-if="requestStatus=='APPROVED'" class="form-floating">
      <input type="text" inputmode="text" class="form-control" placeholder="upiId" id="upiId" v-model="upiId" required>
      <label for="name">UPI ID</label>
    </div>
    <div class="row mb-2"></div>
    <div v-if="requestStatus=='APPROVED'" class="form-floating">
      <input type="text" inputmode="text" class="form-control" placeholder="utr" id="utr" v-model="utr" required>
      <label for="name">UTR</label>
    </div>
    <div class="row mb-2"></div>
    <div v-if="requestStatus=='APPROVED'" class="form-floating">
      <input type="datetime-local" class="form-control" placeholder="transferDate" id="transferDate" v-model="transferDate" required>
      <label for="name">Transfer Date</label>
    </div>
    <div class="row mb-2"></div>
    <div>
      <button type="submit" class="btn btn-primary col-12">Change Status</button>
    </div>
  </form>
</template>

<script>
import AppHeader from '@/components/AppHeader.vue'
import { getLoggedInUser } from '@/storage/auth.module'
import api from '@/storage/api'
import Mixpanel from 'mixpanel-browser'

export default {
  name: 'WalletRedeemRequest',
  components: {
    AppHeader
  },
  data () {
    return {
      userId: getLoggedInUser().userId,
      userRole: getLoggedInUser().userRole,
      userToken: getLoggedInUser().userToken,
      requestId: '',
      requestDetails: [],
      upiId: '',
      utr: '',
      transferDate: '',
      requestStatus: ''
    }
  },
  mounted () {
    Mixpanel.track('Bank Transfer Request Page Viewed', {
      requestId: this.$route.query.id
    })
  },
  created () {
    this.requestId = this.$route.query.id
    this.fetchRequestDetails()
  },
  methods: {
    async submitRequest () {
      const requestBody = {
        id: this.requestId,
        fromVpa: this.upiId,
        utr: this.utr,
        transferDate: this.transferDate,
        walletRedeemRequestStatus: this.requestStatus
      }
      api.post(`/wallet/redeem/status?uuid=${this.userToken}`, requestBody)
        .then(response => {
          console.log(response.data)
          this.fetchRequestDetails()
        })
        .catch(error => {
          console.log(error)
        })
    },
    fetchRequestDetails () {
      api.get(`/wallet/requests/${this.requestId}?uuid=${this.userToken}`)
        .then(response => {
          this.requestDetails = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    formattedDate (timestamp) {
      const date = new Date(timestamp)
      return `${date.toLocaleDateString()}`
    }
  }
}
</script>

<style scoped>

</style>
