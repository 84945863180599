<template>
  <splash-screen v-if="loading" />
  <app-header title="My Orders"/>
  <div class="mt-1">&nbsp;</div>
  <div class="mt-5">
    <div class="text-center" v-if="loading">
    <p  class="text-black-50">Loading...
    <img src="../../assets/static/media/dizzy.gif" class="align-bottom" style="height:20px;">
  </p>
    </div>
    <div v-else>

<!--      <p class="text-muted">{{ userId }} {{ userRole }} {{ userToken }}</p>-->
      <div class="d-flex justify-content-between px-2 mt-3">
        <div class="dropdown mb-3">
          <button class="mx-2 btn btn-secondary  btn-sm dropdown-toggle" style="padding:5px 10px;" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
            Status: {{selectedOptionDisplayName }} ({{filteredOrders.length}})
          </button> <img src="../../assets/static/media/backhand_index_pointing_left.gif" style="height:30px;">
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
<!--            <li><a class="dropdown-item selected" href="#" @click="selectOption('ALL', 'All')">All</a></li>-->
            <li><a class="dropdown-item" href="#" @click="selectOption('INITIATED', 'Order placed')">Order placed</a></li>
            <li><a class="dropdown-item" href="#" @click="selectOption('READY', 'Ready to dispatch')">Ready to dispatch</a></li>
            <li><a class="dropdown-item" href="#" @click="selectOption('COMPLETED', 'Dispatched')">Dispatched</a></li>
            <li><a class="dropdown-item" href="#" @click="selectOption('SETTLED', 'Delivered')">Delivered</a></li>
            <li><a class="dropdown-item" href="#" @click="selectOption('REJECTED', 'Cancelled')">Cancelled</a></li>
<!--            <li><a class="dropdown-item" href="#" @click="selectOption('RECEIVED')">Order received</a></li>-->
<!--            <li><a class="dropdown-item" href="#" @click="selectOption('ACCEPTED')">Order accepted</a></li>-->
          </ul>
        </div>
        <a class="card-action-link mx-2 my-2" href="#" v-if="userRole!='CUSTOMER'" @click.prevent="printLabels()"><i class="fa-solid fa-print"></i></a>
        <a class="card-action-link mx-2 my-2" href="#" @click.prevent="showSearch = !showSearch"><i class="fa-solid fa-magnifying-glass"></i></a>
      </div>
<!--      <div class="d-flex justify-content-between px-2 mt-3">-->
<!--        <p class="my-header">Pending orders</p>-->
<!--        <a class="card-action-link mx-2" href="#"  data-bs-toggle="modal" data-bs-target="#exampleModal">change status</a>-->
<!--      </div>-->
      <div v-if="showSearch" class="input-group search-box mb-2">
        <input type="text" class="form-control mx-3" placeholder="Search order ID, product code, customer name" v-model="searchQuery">
        <button type="button" class="btn bg-transparent" style="margin-right:10px;border: none;" @click.prevent="showSearch = !showSearch">
          <i class="fa fa-times"></i>
        </button>
      </div>
      <div v-if="filteredOrders.length==0" class="container text-center mt-5">
        <img src="../../assets/static/media/ghost.gif" style="height:200px;">
        <h4>No order in <b style="font-weight: 700;">{{selectedOptionDisplayName}}</b>!</h4>
        <p>You can order new products.</p>
        <router-link class="btn-secondary-orange btn mb-5" to="/home">Order Now</router-link>
      </div>
      <div class="card desc-card card-navigation mb-2 mx-3" v-for="order in filteredOrders" :key="order.orderId" style="height: auto;">
        <router-link :to="'/order/' + order.orderCode">
    <div class="d-flex justify-content-between">
      <div class="col-8">
        <h5 class="card-title mb-1" style="font-weight: 400;color:#767676;font-size:12px;">{{ order.paymentMode }} Order# {{ order.orderCode }}</h5>
<!--        <p class="card-text">On {{ formattedDate(order.createdDate) }}</p>-->
        <h6 class="card-subtitle mt-0 text-muted" v-if="userRole=='CUSTOMER'">To: {{order.store.displayName}}</h6>
<!--        <h6 class="card-subtitle mt-0" v-if="userRole=='MERCHANT'">From: {{order.resellerName}}</h6>-->
        <h6 class="card-subtitle mt-0 text-muted" v-if="userRole!=='CUSTOMER'">Store: {{order.store.displayName}} | Reseller: {{order.resellerName}}</h6>
        <!--        <h6 class="card-subtitle">Rs. {{ order.orderValue }}</h6>-->
        <h6 class="card-subtitle mt-1 text-muted">Customer name:
          <span v-for="item in order.orderItem" :key="item.id"> {{ order.deliveryAddress.name }}</span>
        </h6>
        <h6 class="card-subtitle mt-1 text-muted">Product code:
          <span v-for="item in order.orderItem" :key="item.id"> {{ item.productCode }}, </span>
        </h6>
        <p class="card-text mt-2 mb-0">
        <span v-for="item in order.orderItem" :key="item.id">
          <img :alt="item.itemId" :src=item.img style="height: 50px;width: 50px;"/> &nbsp;
        </span>
        </p>
      </div>
      <div class="col-2">
          <span class="float-end text-primary" style="font-weight: 500;font-size: 14px">
            <div class="card-subtitle-badge"><span class="badge" style="color:#2d2d2d;background: #efefef;">{{order.orderStatusDisplayName}}</span></div>
<!--            <router-link :to="'/order/' + order.code" class="card-action-link">View</router-link>-->
          </span>
      </div>
    </div></router-link>
      </div>
    </div>
  </div>
  <nav-bottom></nav-bottom>
  <app-footer class="mt-3"/>
</template>

<script>
import AppHeader from '@/components/AppHeader.vue'
import { useRoute } from 'vue-router'
import { getLoggedInUser } from '@/storage/auth.module'
import router from '@/router'
import api from '@/storage/api'
import SplashScreen from '@/components/SplashScreen.vue'
import Mixpanel from 'mixpanel-browser'
import NavBottom from '@/components/NavBottom.vue'
import AppFooter from '@/components/AppFooter.vue'

Mixpanel.init(process.env.VUE_APP_MIXPANEL_TOKEN)

export default {
  components: {
    AppFooter,
    NavBottom,
    AppHeader,
    SplashScreen
  },
  data () {
    return {
      orderDetails: [],
      loading: true,
      searchQuery: '',
      selectedOption: 'INITIATED',
      selectedOptionDisplayName: 'Order placed',
      filters: {
        INITIATED: { name: 'Order placed' },
        READY: { name: 'Ready to dispatch' },
        COMPLETED: { name: 'Dispatched' },
        SETTLED: { name: 'Delivered' },
        REJECTED: { name: 'Cancelled' }
      },
      showSearch: false,
      userId: getLoggedInUser().userId,
      userRole: getLoggedInUser().userRole,
      userToken: getLoggedInUser().userToken
    }
  },
  mounted () {
    Mixpanel.track('Order Page Viewed')
  },
  created () {
    const orderFilterValue = sessionStorage.getItem('selectedOption')
    if (orderFilterValue) {
      this.selectOption(orderFilterValue, this.filters[orderFilterValue].name)
    }
    this.getOrderByStatus(this.selectedOption)
  },
  methods: {
    getOrderByStatus (status) {
      api.get(`/orders/${status}/0/200?uuid=${this.userToken}`)
        .then(response => response.data)
        .then(data => {
          this.orderDetails = data.content
          this.loading = false
        })
        .catch(error => console.error(error))
    },
    printLabels () {
      router.push({
        name: 'orderPrint'
      })
    },
    selectOption (option, displayName) {
      // alert(option)
      this.selectedOption = option
      this.selectedOptionDisplayName = displayName
      this.getOrderByStatus(this.selectedOption)
    },
    formattedDate (timestamp) {
      const date = new Date(timestamp)
      return `${date.toLocaleDateString()}`
    }
  },
  computed: {
    filteredOrders () {
      let filtered = this.orderDetails
      if (this.searchQuery) {
        const query = this.searchQuery.toLowerCase()
        // filtered = filtered.filter(order => order.orderCode && order.orderCode.includes(this.searchQuery))
        filtered = filtered.filter(order => {
          return (order.orderCode && order.orderCode.toLowerCase().includes(query)) ||
            (order.deliveryAddress.name && order.deliveryAddress.name.toLowerCase().includes(query)) ||
          order.orderItem.some(item => {
            const code = item.productCode ? item.productCode.toLowerCase() : '' // convert item code to lowercase
            return code.includes(query)
          })
        })
      }
      if (this.selectedOption === 'ALL') {
        return filtered
      }
      if (this.selectedOption) {
        Mixpanel.track('Order ID Filtered', {
          option: this.selectedOption
        })
        sessionStorage.setItem('selectedOption', this.selectedOption)
        filtered = filtered.filter(order => order.orderStatus === this.selectedOption)
      }
      return filtered
    }
  }
}

</script>

<style scoped>
.nav-icon .nav-pills .nav-link.active, .nav-pills .show > .nav-link{
  background: #F6F6F6;
}
</style>
// fetch('https://dummyjson.com/cart/1')
