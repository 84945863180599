<template>
  <div class="splash-screen">
<!--    <div class="spinner-border text-primary" role="status">-->
<!--      <span class="visually-hidden">Loading...</span>-->
<!--    </div>-->
    <img alt="eSetu" class="rounded-circle rotate-image" src="../assets/icon-72x72.svg">
  </div>
</template>

<style scoped>
.splash-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFF;
  z-index: 9999;
}
</style>
