<template>
  <app-header title="Add Item" showBackBtn/>
  <div class="mt-5">&nbsp;</div>
<!--  <p class="text-muted">{{ userId }} {{ userRole }} {{ userToken }}</p>-->
  <!-- toast container -->
  <form class="container" @submit.prevent="addItem">
    <div class="dropdown mb-3">
    <button class="btn btn-secondary  btn-sm dropdown-toggle" style="padding:5px 10px;" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
      Category: {{selectedOption}}
    </button>
    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton" required>
      <li><a class="dropdown-item" href="#" @click="selectOption('KURTI')">Kurti</a></li>
      <li><a class="dropdown-item" href="#" @click="selectOption('SAREE')">Saree</a></li>
      <li><a class="dropdown-item" href="#" @click="selectOption('SUITMATERIAL')">Suit Material</a></li>
      <li><a class="dropdown-item" href="#" @click="selectOption('LEHENGACHOLI')">Lehenga Choli</a></li>
      <li><a class="dropdown-item" href="#" @click="selectOption('GOWN')">Gown</a></li>
      <li><a class="dropdown-item" href="#" @click="selectOption('READYBLOUSE')">Ready Blouse</a></li>
      <li><a class="dropdown-item" href="#" @click="selectOption('NIGHTSUITS')">Night Suits</a></li>
      <li><a class="dropdown-item" href="#" @click="selectOption('KURTA')">Kurta</a></li>
    </ul>
  </div>
      <div class="row mb-1"></div>
<!--      <div class="form-floating">-->
<!--        <select style="border-radius: 8px;" v-model="selectedStore" class="form-select" id="floatingSelect" aria-label="Floating label select example">-->
<!--          <option selected value="">Select</option>-->
<!--          <option v-for="store in stores" :key="store.id" :value="store.id">-->
<!--            {{ store.displayName }}-->
<!--          </option>-->
<!--        </select>-->
<!--        <label for="floatingSelect">Store</label>-->
<!--      </div>-->
<!--      <div class="row mb-2"></div>-->
      <div class="form-floating">
        <input type="text" inputmode="text" class="form-control" placeholder="code" id="code" v-model="code" required>
        <label for="name">Product code</label>
      </div>
      <div class="row mb-2"></div>
      <div class="form-floating">
        <input type="text" inputmode="text" class="form-control" placeholder="title" id="title" v-model="title" required>
        <label for="name">Product title</label>
      </div>
      <div class="row mb-2"></div>
      <div class="form-floating">
        <textarea style="height:100px;" type="text" inputmode="text" class="form-control" placeholder="Product description" id="description" v-model="description" required />
        <label for="name">Product description</label>
      </div>
      <div class="row mb-2"></div>
      <div class="form-floating">
        <input type="number" v-bind:max="5000"  pattern="[0-9]*" inputmode="numeric" class="form-control" placeholder="weight" id="weight" v-model="weight" required>
        <label for="name">Weight</label>
      </div>
      <div class="row mb-2"></div>
      <div class="input-group">
        <span class="input-group-text">L x B x H</span>
        <input type="number" pattern="[0-9]*" inputmode="numeric" aria-label="l" class="form-control" placeholder="Length" v-model="len">
        <input type="number" pattern="[0-9]*" inputmode="numeric" aria-label="b" class="form-control" placeholder="Breadth" v-model="breadth">
        <input type="number" pattern="[0-9]*" inputmode="numeric" aria-label="h" class="form-control" placeholder="Height" v-model="width">
      </div>
      <div class="row mb-2"></div>
      <div class="form-floating">
        <input type="number" pattern="[0-9]*" inputmode="numeric" class="form-control" placeholder="price" id="price" v-model="price" required>
        <label for="name">Price (in Rs.)</label>
      </div>
    <div class="row mb-2"></div>
    <div class="input-group mb-2">
      <input type="file" ref="fileInput" @change="handleImagesUpload" class="form-control" id="formFiles" multiple>
      <div class="input-group-append">
        <button v-if="loading" class="input-group-text btn btn-outline-primary" disabled><img src="../../assets/static/media/dizzy.gif" class="align-bottom" style="height:20px;"></button>
        <a v-else-if="files.length >= 1" class="input-group-text btn btn-outline-primary" @click="uploadImages" v-bind:disabled="thumbnailUrls.length > 0">Upload</a>
<!--        <button v-else class="input-group-text btn btn-outline-primary" @click="uploadImages" v-bind:disabled="thumbnailUrl !== null">Upload</button>-->
      </div>
    </div>
      <div class="row mb-1"></div>
    <div class="container" v-if="thumbnailUrls.length > 0">
      <div class="card desc-card tracker-card">
        <h5 class="card-title-uppercase mb-3">Images uploaded</h5>
        <div class="d-flex">
          <span v-for="(thumbnailUrl, index) in thumbnailUrls" :key="index">
            <img :alt="thumbnail" :src=thumbnailUrl style="height: 100px;border-radius: 8px;"/> &nbsp;
            </span>
        </div>
      </div>
    </div>
      <nav class="navbar fixed-bottom navbar-light bg-light btn-nav">
        <button v-if="disableAddBtn" class="btn btn-primary w-100" disabled><img src="../../assets/static/media/dizzy.gif" class="align-bottom" style="height:20px;"></button>
        <button v-else type="submit" :disabled="!isFormValid" class="btn btn-primary w-100">Add item</button>
      </nav>
    </form>

  <div class="d-flex justify-content-between mt-3">
    <p class="my-header text-black-50">Customisation</p>
<!--    <a class="card-action-link mx-2 my-1" href="#" @click.prevent="showSearch = !showSearch">Add new</a>-->
    <a class="card-action-link mx-3" href="#"  data-bs-toggle="modal" data-bs-target="#exampleModal">Add new</a>
  </div>

<!--  <div class="modal-body" v-if="formCustomisationSaved">-->
<!--    <p>Selected sizes: {{ selectedSizeOptions }}</p>-->
<!--    <p>Product image: {{ image }}</p>-->
<!--  </div>-->

  <div v-if="formCustomisationSaved" class="mb-5">
  <div class="card desc-card mb-2 mx-3" v-for="(customization, index) in customizations" :key="index" style="max-height: 130px;">
    <input type="hidden" v-model="selectedCustomizationIndex">
    <div class="d-flex justify-content-between">
      <div class="col-8">
<!--        <div class="card-subtitle-badge mb-2 mt-0"></div>-->
<!--        <div class="badge" :style="'background:'+ customization.colorCode" style="height: 50px; width: 50px;">Color</div>-->
<!--        <img :src="customization.img" style="border: 1px solid #cecece;">-->
<!--        <h5 class="card-title mb-2" style="font-weight: 400;color:#767676;font-size:12px;">Code: {{ code }}:{{ index + 1 }}</h5>-->
        <h5 class="card-title mb-2" style="font-weight: 400;color:#767676;font-size:12px;">Color: {{ customization.colorName }} <span class="badge" :style="'background:'+ customization.colorCode" style="color:#2d2d2d;height: 12px; width: 12px;">&nbsp;</span></h5>
        <p class="card-text">Size:
          <span v-for="size in customization.size" :key="size" >{{ size }}, </span>
        </p>
      </div>
      <div class="col-2" v-if="userRole!=='CUSTOMER'">
          <span class="float-end text-primary" style="font-weight: 500;font-size: 14px">
             <a @click="removeSku(index)" class="card-action-link mx-2 text-danger">Remove</a>
          </span>
      </div>
    </div>
  </div>
  </div>
  <div class="mb-5 mt-5">&nbsp;</div>
<!--  <div class="modal-body" v-if="formCustomisationSaved">-->
<!--    <div v-for="(customization, index) in customizations" :key="index">-->
<!--      <p>Customization {{ index + 1 }}</p>-->
<!--      <p>Selected sizes: {{ customization.size }}</p>-->
<!--      <p>Product image: {{ customization.image }}</p>-->
<!--    </div>-->
<!--  </div>-->

  <!-- Modal -->
  <div class="modal"  id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Customisation</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="saveCustomisationForm">
            <div class="form-group">
              <label for="select-options">Select Color</label>
              <select id="select-options" class="form-control" v-model="selectedColorOption" style="height: 50px;">
                <option selected value="">Select</option>
                <option v-for="option in colorOptions" :key="option.value" :value="option.value">{{ option.name }}</option>
              </select>
            </div>
            <div class="row mb-3"></div>
            <div class="form-group">
              <label for="select-options">Select size</label>
              <select id="select-options" class="form-control" multiple v-model="selectedSizeOptions" style="height: 50px;">
                <option v-for="option in sizeOptions" :key="option.value" :value="option.value">{{ option.text }}</option>
              </select>
            </div>
<!--            <label for="basic-url" class="mt-2">Upload image</label>-->
<!--            <div class="input-group mb-3">-->
<!--              <input type="file" ref="fileInput" @change="handleImageUpload" class="form-control" id="formFile">-->
<!--              <div class="input-group-append">-->
<!--                <button v-if="loading" class="input-group-text btn btn-outline-primary" @click="uploadImage" disabled><img src="../../assets/static/media/dizzy.gif" class="align-bottom" style="height:20px;"></button>-->
<!--                <button v-else class="input-group-text btn btn-outline-primary" @click="uploadImage" v-bind:disabled="thumbnailUrl !== null">Upload</button>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div v-show="false" class="form-floating mb-1">-->
<!--              <input type="text" inputmode="text" class="form-control" placeholder="image" id="image" v-model="img" required>-->
<!--              <label for="name">Product image</label>-->
<!--            </div>-->
            <div class="modal-footer">
              <button :disabled="!isCustomisationFormValid" type="submit" data-bs-dismiss="modal" class="btn btn-primary col-12">Add</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppHeader from '@/components/AppHeader.vue'
import { getLoggedInUser } from '@/storage/auth.module'
import axios from 'axios'
import api from '@/storage/api'
import router from '@/router'
import Mixpanel from 'mixpanel-browser'
import color from '@/data/color'
import size from '@/data/size'
import { toast } from 'vue3-toastify'
export default {
  name: 'ItemAdd',
  components: {
    AppHeader
  },
  data () {
    return {
      userId: getLoggedInUser().userId,
      userRole: getLoggedInUser().userRole,
      userToken: getLoggedInUser().userToken,
      selectedStore: '',
      selectedOption: '',
      code: '',
      title: '',
      description: '',
      weight: '',
      len: '',
      breadth: '',
      width: '',
      price: '',
      imgFiles: '',
      // isFormValid: false,
      errorMessage: '',
      selectedSizeOptions: [],
      sizeOptions: size,
      selectedColorOption: '',
      colorOptions: color,
      img: '',
      imageUrl: null,
      thumbnailUrl: null,
      file: null,
      files: [],
      imgs: [],
      thumbnailUrls: [],
      selectedCustomizationIndex: null,
      formCustomisationSaved: false,
      customizations: [],
      stores: [],
      loading: false,
      disableAddBtn: false
    }
  },
  created () {
    this.getStores()
  },
  methods: {
    getStores () {
      api.get('/store/all')
        .then(response => response.data)
        .then(data => {
          this.stores = data
        })
        .catch(error => console.error(error))
    },
    selectOption (option) {
      this.selectedOption = option
      const menuItems = document.querySelectorAll('.dropdown-menu a')
      menuItems.forEach(item => {
        if (item.innerText === option) {
          item.classList.add('selected')
        } else {
          item.classList.remove('selected')
        }
      })
    },
    addItem () {
      this.disableAddBtn = true
      // create a new item object
      const item = {
        // storeId: this.selectedStore,
        category: this.selectedOption,
        code: this.code,
        title: this.title,
        description: this.description,
        weight: this.weight,
        length: this.len,
        breadth: this.breadth,
        width: this.width,
        price: this.price,
        customisation: this.customizations,
        imgs: this.imgs
      }
      api.post(`/items?uuid=${this.userToken}`, {
        ...item
      })
        .then(response => {
          // handle the API response here
          if (response.status === 200) {
            // reset the form
            // this.selectedStore = ''
            this.selectedOption = ''
            this.code = ''
            this.title = ''
            this.description = ''
            this.weight = ''
            this.len = ''
            this.breadth = ''
            this.width = ''
            this.price = ''
            this.customizations = ''
            this.imgs = ''
            this.thumbnailUrls = ''
            this.file = ''
            this.files = ''
            Mixpanel.track('Item', {
              status: 'Success',
              requestId: response.data.id,
              productCategory: item.category
            })
            toast.success('Item has been added successfully')
            this.disableAddBtn = false
          } else {
            // display error toast message
            this.errorMessage = 'An error occurred while adding the item.'
            Mixpanel.track('Item', { status: 'Failure', errorMessage: this.errorMessage })
            toast.error(this.errorMessage)
            this.disableAddBtn = false
          }
        })
        .catch(error => {
          this.errorMessage = error.message
          Mixpanel.track('Item', { status: 'Failure', errorCode: error.code, errorMessage: error.message })
          toast.error(this.errorMessage)
        })
    },
    saveCustomisationForm () {
      const selectedColor = color.find(color => color.value === this.selectedColorOption)
      this.customizations.push({
        selectedCustomizationIndex: this.customizations.length + 1,
        color: selectedColor.value,
        colorName: selectedColor.name,
        colorCode: selectedColor.code,
        size: this.selectedSizeOptions,
        img: this.thumbnailUrl
      })
      this.selectedColorOption = ''
      this.selectedSizeOptions = []
      this.img = ''
      this.file = ''
      this.$refs.fileInput.value = '' // Reset file input field
      // You could save the form data to a database or store it in a state management library
      // In this example, we're just setting a flag to indicate that the form has been saved
      this.formCustomisationSaved = true
      this.thumbnailUrl = null
    },
    handleImagesUpload (event) {
      this.files = Array.from(event.target.files)
    },
    handleImageUpload (event) {
      this.file = event.target.files[0]
    },
    removeSku (index) {
      this.customizations.splice(index, 1)
    },
    async uploadImage () {
      this.loading = true
      const formData = new FormData()
      formData.append('file', this.file)
      formData.append('upload_preset', process.env.VUE_APP_CLOUDINARY_TOKEN)
      try {
        const response = await axios.post(
          process.env.VUE_APP_CLOUDINARY_ENDPOINT,
          formData
        )
        this.imageUrl = response.data.secure_url
        this.thumbnailUrl = this.generateThumbnailUrl(response.data.public_id)
        this.img = this.thumbnailUrl
        this.loading = false
      } catch (error) {
        console.error(error)
        this.loading = false
        // Handle the error here, for example by showing a message to the user or logging the error.
      }
      // const response = await axios.post(
      //   'https://api.cloudinary.com/v1_1/lf/image/upload',
      //   formData
      // )
      // this.imageUrl = response.data.secure_url
      // this.thumbnailUrl = this.generateThumbnailUrl(response.data.public_id)
      // this.img = this.thumbnailUrl
      // this.loading = false
    },
    generateThumbnailUrl (publicId) {
      return process.env.VUE_APP_CLOUDINARY_THUMB_ENDPOINT + `${publicId}`
    },
    async uploadImages () {
      this.loading = true
      const uploadPromises = this.files.map(file => {
        const formData = new FormData()
        formData.append('file', file)
        formData.append('upload_preset', process.env.VUE_APP_CLOUDINARY_TOKEN)
        return axios.post(process.env.VUE_APP_CLOUDINARY_ENDPOINT, formData)
      })
      try {
        const responses = await Promise.all(uploadPromises)
        this.thumbnailUrls = responses.map(response => this.generateThumbnailUrl(response.data.public_id))
        this.imgs = this.thumbnailUrls.reduce((acc, url) => {
          acc.push({ img: url })
          return acc
        }, [])
        this.loading = false
      } catch (error) {
        console.error(error)
        this.loading = false
        // Handle the error here, for example by showing a message to the user or logging the error.
      }
    }
  },
  computed: {
    // validate the form fields and enable/disable the submit button
    isFormValid () {
      return this.selectedOption !== '' &&
        // this.selectedStore !== '' &&
        this.customizations.length !== 0 &&
        this.imgs.length !== 0 &&
        this.code !== '' &&
        this.title !== '' &&
        this.description !== '' &&
        this.weight !== '' &&
        this.price !== '' &&
        this.len !== '' &&
        this.breadth !== '' &&
        this.height !== ''
    },
    isCustomisationFormValid () {
      return this.selectedColorOption !== '' &&
        this.selectedSizeOptions.length !== 0
    }
    // isCustomisationFormValid () {
    //   return this.imageUrl != null &&
    //     this.selectedSizeOptions != null
    // }
  }
}
</script>

<style scoped>
.modal-dialog {
  position: fixed;
  bottom: 0;
  left: 0%;
  right: 0%;
  transform: translate(-50%, -50%);
}
</style>

// const file = event.target.files[0]
// const formData = new FormData()
// formData.append('key', '000035db5ffe257f34f635b5861baaaa')
// // formData.append('source', file)
// // formData.append('format', 'json')
// formData.append('media', file)
//
// axios.post('https://thumbsnap.com/api/upload', formData)
//   .then(response => {
//     this.img = response.data.data.thumb
//     console.log(response.data)
//     // Handle response from the server
//   })
//   .catch(error => {
//     console.log(error.response.data)
//     // Handle error response from the server
//   })
