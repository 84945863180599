<template>
<!--  <div class="card-footer card-footer-info">-->
<!--    Order cancellation reason: xyz-->
<!--  </div>-->
  <div class="px-3 py-2 notice-info text-center" style="text-align: left;background: #FFF5D9;font-weight: 600;font-size: 12px;">
    <img src="../../assets/static/media/bell.gif" style="height:20px;"> Tap "Download Images" & share. Visit product page now!
  </div>
  <div class="p-2">
    <div class="input-group search-box">
      <input type="text" class="form-control" placeholder="Search product code" v-model="query">
      <button v-if="query.length>=2" type="button" class="btn" style="border:none;color:#5E5FD9;font-weight: 700;" @click="search(query)">
        <i class="fa fa-search"></i> SEARCH
      </button>
    </div>
  </div>
  <p class="my-header mt-2" style="color: #909090;">Scroll the feed<img src="../../assets/static/media/backhand_index_pointing_down.gif" style="height:30px;"></p>
  <div class="container">
    <div class="card-group row">
      <div class="card col-6 catalog-card mb-0 mt-0 py-0" v-for="item in items" :key="item.id" style="box-shadow: none;border-radius: 0;">
        <div class="card-body">
          <router-link :to="'/store/product/'+ item.setuCode" @click="itemClickEvent(item.setuCode, item.code)">
          <div class="text-center">
<!--            <span class="MuiTypography-root MuiTypography-small css-ahj6ry">In Stock</span>-->
            <div class="css-50wgu">
            <img alt="..." :src="item.img.replace('w_200,h_200', 'w_400,h_400')" style="width: 100%;height: 100%;border-radius: 8px;"></div>
          </div>
          <!--            <img alt="..." v-lazy="item.img.replace('w_200,h_200', 'w_400,h_400')" style="width: 100%;height: 100%;border-radius: 8px;"></div>-->
          <p class="product-title mt-2 mb-1 text-muted">{{item.title}}</p>
            <p class="product-title mb-1">{{item.code}}
            <span class="badge new-purple shining-effect" v-if="isLatest(item.createdDate)">New arrival</span>
<!--            <span class="badge upcoming" v-if="item.displayPrice<=300" >Trending</span>-->
          </p>
        </router-link>
          <div class="d-flex justify-content-between">
            <p class="product-cp mt-1">Rs. {{item.displayPrice}}</p>
<!--            <a target="_blank" class="card-action-link" @click="downloadImage(itemDetails.code, itemDetails.imgs)" style="font-weight: 600;cursor: pointer;font-size: 16px;"><i class="fa-solid fa-download"></i></a>-->
          </div>
          <h6 class="card-subtitle mt-2 text-muted">added {{timeSinceLastItemUpdated(item.createdDate)}}</h6>
        </div>
      </div>
    </div>
  </div>
<!--  <infinite-loading target="#app" @infinite="getItemFeed"></infinite-loading>-->
  <infinite-loading ref="infiniteLoading" @infinite="getItemFeed" spinner="bubbles" direction="bottom" :distance="10">
  </infinite-loading>
<!--  <div class="text-center text-black-50">{{endOfPageMessage}}</div>-->
</template>

<script>
import { getLoggedInUser } from '@/storage/auth.module'
import api from '@/storage/api'
import Mixpanel from 'mixpanel-browser'
import { timeSinceLastItemUpdated } from '@/utility/utils'
import { getCart } from '@/storage/cart.module'
import router from '@/router'

export default {
  name: 'ItemFeed',
  emits: ['$InfiniteLoading:loaded'],
  data () {
    return {
      store: [],
      items: [],
      page: 0,
      perPage: 10,
      query: '',
      userId: getLoggedInUser().userId,
      userRole: getLoggedInUser().userRole,
      userToken: getLoggedInUser().userToken,
      savedStoreId: getCart().storeId,
      savedCartItems: getCart().cartItems,
      savedCartValue: getCart().cartValue,
      savedCartWeight: getCart().cartWeight,
      savedCartShippingCost: getCart().cartShippingCost
    }
  },
  mounted () {
    this.getStoreDetails()
    // this.getItemFeed()
  },
  computed: {
    endOfPageMessage () {
      if (this.items.length === 0) {
        return ''
      }
      if (this.items.length < (this.page + 1) * this.perPage) {
        return this.items.length + ' products viewed.'
      }
      return ''
    },
    today () {
      return new Date().toISOString().substr(0, 10)
    }
  },
  methods: {
    timeSinceLastItemUpdated,
    isLatest (createdDate) {
      return createdDate.substr(0, 10) === this.today
    },
    search (query) {
      router.push({
        name: 'search',
        query: { keyword: query }
      })
    },
    getItemFeed () {
      api.get(`/items/feed/${this.page}/${this.perPage}?uuid=${this.userToken}`)
        .then(response => response.data)
        .then(data => {
          const newitems = data.content
          this.items = [...this.items, ...newitems]
          this.page++
          this.$emit('$InfiniteLoading:loaded')
          Mixpanel.track('Product feed scrolled', {
            'Number of pages viewed': this.page,
            'Number of items viewed': this.items.length
          })
        })
        .catch(error => {
          console.error(error)
          this.$refs.infiniteLoading.$emit('$InfiniteLoading:error')
        })
    },
    getStoreDetails () {
      api.get('/store/my?uuid=' + this.userToken)
        .then(response => response.data)
        .then(data => {
          this.store = data
        })
        .catch(error => {
          console.error(error)
        })
    },
    itemClickEvent (itemSetuCode, itemCode) {
      Mixpanel.track('Product clicked', {
        'Item Setu Code': itemSetuCode,
        'Item Code': itemCode
      })
    }
  }
}
</script>

<style scoped>
</style>
