<template>
<!--  <app-header/>-->
  <splash-screen v-if="loading"/>
  <div v-else>
  <div class="py-1 px-3 mt-0 mb-0 bg-white">
<!--    <p v-if="loading" class="text-black-50">Loading...-->
<!--      <img src="../assets/static/media/dizzy.gif" class="align-bottom" style="height:20px;">-->
<!--    </p>-->
    <div class="container">
      <div class="row">
        <div class="col-2 mt-2">
          <img alt="eSetu" class="rounded-circle rotate-image img-fluid" src="../assets/icon-72x72.svg">
        </div>
        <div class="col-10 mt-3 align-middle">
          <h2 class="px-3">Hi, {{ shortenedName(loggedinUser.waName) }}</h2>
          <p class="px-3 text-black-50" style="margin-top:-10px;">Explore & order!</p>
        </div>
      </div>
    </div>
  </div>
  <supplier-home v-if="userRole=='MERCHANT'"></supplier-home>
  <item-feed v-if="userRole!=='MERCHANT'"></item-feed>
  <nav-bottom></nav-bottom>
  <app-footer class="mt-0"/></div>
</template>

<script>

import AppFooter from '@/components/AppFooter.vue'
import { getLoggedInUser, setUser } from '@/storage/auth.module'
import Mixpanel from 'mixpanel-browser'
import router from '@/router'
import api from '@/storage/api'
import SplashScreen from '@/components/SplashScreen.vue'
import NavBottom from '@/components/NavBottom.vue'
import ItemFeed from '@/components/feed/ItemFeed.vue'
import SupplierHome from '@/components/feed/SupplierHome.vue'

export default {
  components: {
    SupplierHome,
    ItemFeed,
    NavBottom,
    AppFooter,
    SplashScreen
  },
  props: {
    token: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      user: [],
      loggedinUser: {
        id: 'any',
        role: 'any',
        uuid: 'any'
      },
      userId: getLoggedInUser().userId,
      userRole: getLoggedInUser().userRole,
      userToken: getLoggedInUser().userToken,
      stores: [],
      store: [],
      query: ''
    }
  },
  created () {
    this.fetchUser()
  },
  mounted () {
    Mixpanel.track('Home Page Viewed')
  },
  methods: {
    shortenedName (text) {
      const nameArr = text.split(' ')
      if (nameArr.length >= 2) {
        return `${nameArr[0]} ${nameArr[1]}...`
      } else {
        return text
      }
    },
    fetchUser () {
      let uri = '/users/me?uuid=' + this.userToken
      if (this.token != null) {
        uri = '/users/me?uuid=' + this.token
      }
      api.get(uri)
        .then(response => response.data)
        .then(data => {
          this.loggedinUser = data
          setUser(this.loggedinUser.id, this.loggedinUser.role, this.loggedinUser.uuid)
          this.userId = getLoggedInUser().userId
          this.userRole = getLoggedInUser().userRole
          this.userToken = getLoggedInUser().userToken
          this.loading = false
        })
        .catch(error => {
          console.error(error)
          router.push({
            name: 'login'
          })
        })
    }
  }
}
</script>

<style scoped>
.nav-icon .nav-pills .nav-link.active, .nav-pills .show > .nav-link{
  background: #F6F6F6;
}
</style>
