<template>
  <div class="p-2">
    <div class="input-group search-box">
      <input type="text" class="form-control" placeholder="Search product code" v-model="query">
      <button v-if="query.length>=2" type="button" class="btn" style="border:none;color:#5E5FD9;font-weight: 700;" @click="search(query)">
        <i class="fa fa-search"></i> SEARCH
      </button>
    </div>
  </div>
  <div class="mb-3">
  <p class="my-header text-black-50 mt-2">My Store</p>
  <router-link class="" :to="'/store/' + store.handler" @click="storeClickEvent(userId, store.id, store.handler)">
    <div class="card card-navigation avatar-card" style="margin-bottom: 1px;border-radius: 0;box-shadow: none;">
    <span class="avatar avatar-xs rounded-circle">
       <span v-if="store.profileImg==null">{{store.initials}}</span>
      <img v-else :src=store.profileImg />
      </span>
      <h5 class="card-title mb-2 mt-2">{{ store.displayName }}
        <i class="bi bi-patch-check-fill verified"></i>
      </h5>
      <h6 class="card-subtitle text-muted mb-2">last update {{timeSinceLastItemUpdated(store.lastItemAddedDate)}}</h6>
    </div></router-link>
  </div>
</template>

<script>
import { getLoggedInUser } from '@/storage/auth.module'
import api from '@/storage/api'
import { timeSinceLastItemUpdated } from '@/utility/utils'
import router from '@/router'

export default {
  name: 'SupplierHome',
  data () {
    return {
      userId: getLoggedInUser().userId,
      userRole: getLoggedInUser().userRole,
      userToken: getLoggedInUser().userToken,
      store: [],
      query: ''
    }
  },
  created () {
    this.getStoreDetails()
  },
  methods: {
    timeSinceLastItemUpdated,
    getStoreDetails () {
      api.get('/store/my?uuid=' + this.userToken)
        .then(response => response.data)
        .then(data => {
          this.store = data
        })
        .catch(error => {
          console.error(error)
        })
    },
    search (query) {
      router.push({
        name: 'search',
        query: { keyword: query }
      })
    }
  }
}
</script>

<style scoped>

</style>
