export const setCart = (storeId: any, newItem: any, cartItems: Array<any>, cartValue: any, cartWeight: any, cartShippingCost: any) => {
  cartItems.push(newItem)
  const newCartValue = Number(cartValue) + Number(newItem.price * newItem.quantity)
  const newCartWeight = Number(cartWeight) + Number(newItem.weight * newItem.quantity)
  const newCartShippingCost = Number(cartShippingCost) + Number(newItem.shippingCost * newItem.quantity)
  localStorage.setItem('storeId', storeId)
  localStorage.setItem('cartItems', JSON.stringify(cartItems))
  localStorage.setItem('cartValue', String(newCartValue))
  localStorage.setItem('cartWeight', String(newCartWeight))
  localStorage.setItem('cartShippingCost', String(newCartShippingCost))
}
export const updateCart = (storeId: any, cartItems: Array<any>, itemId: string, quantity: number) => {
  const itemIndex = cartItems.findIndex(item => item.id === itemId)
  if (itemIndex === -1) {
    return
  }
  cartItems[itemIndex].quantity = quantity
  let cartValue = 0
  let cartWeight = 0
  let cartShippingCost = 0
  cartItems.forEach(item => {
    cartValue += item.price * item.quantity
    cartWeight += item.weight * item.quantity
    cartShippingCost += item.shippingCost * item.quantity
  })
  // Save the updated values to localStorage
  localStorage.setItem('storeId', storeId)
  localStorage.setItem('cartItems', JSON.stringify(cartItems))
  localStorage.setItem('cartValue', String(cartValue))
  localStorage.setItem('cartWeight', String(cartWeight))
  localStorage.setItem('cartShippingCost', String(cartShippingCost))
}

export const removeFromCart = (storeId: any, itemId: string, cartItems: Array<any>) => {
  const itemIndex = cartItems.findIndex((item: { id: string }) => item.id === itemId)
  if (itemIndex === -1) {
    return
  }
  let cartValue = 0
  let cartWeight = 0
  let cartShippingCost = 0
  cartItems.splice(itemIndex, 1)
  cartItems.forEach(item => {
    cartValue += item.price * item.quantity
    cartWeight += item.weight * item.quantity
    cartShippingCost += item.shippingCost * item.quantity
  })
  if (cartItems.length <= 0) {
    storeId = ''
  }
  // Save the updated values to localStorage
  localStorage.setItem('storeId', storeId)
  localStorage.setItem('cartItems', JSON.stringify(cartItems))
  localStorage.setItem('cartValue', String(cartValue))
  localStorage.setItem('cartWeight', String(cartWeight))
  localStorage.setItem('cartShippingCost', String(cartShippingCost))
}

export const resetCart = () => {
  localStorage.removeItem('storeId')
  localStorage.removeItem('cartItems')
  localStorage.removeItem('cartValue')
  localStorage.removeItem('cartWeight')
  localStorage.removeItem('cartShippingCost')
}
export const getCart = () => {
  const storeId = localStorage.getItem('storeId')
  const cartItems = JSON.parse(localStorage.getItem('cartItems') || '[]')
  const cartValue = localStorage.getItem('cartValue')
  const cartWeight = localStorage.getItem('cartWeight')
  const cartShippingCost = localStorage.getItem('cartShippingCost')
  return { storeId, cartItems, cartValue, cartWeight, cartShippingCost }
}
