<template>
  <app-header title="Search" showBackBtn/>
  <div class="mt-4">&nbsp;</div>
  <p class="my-header text-black-50 mt-4">You've searched <b>"{{query}}"</b></p>

  <div v-if="items.length==0" class="container text-center mt-2">
    <img src="../assets/static/media/ghost.gif" style="height:200px;">
    <h4>There is no product named <br/>"<b style="font-weight: 700;">{{query}}</b>"</h4>
    <p>You can search or order other products.</p>
    <router-link class="btn-secondary-orange btn mb-5" to="/home">Take me there!</router-link>
  </div>

  <div v-for="item in items" :key="item.id">
<!--    <router-link class="" :to="'/store/' + item.storeName +'?search='+ query">-->
    <router-link class="" :to="'/store/' + item.storeName +'/'+ item.setuCode">
      <div class="card card-navigation product-card mb-2 mx-3" style="height: 130px;">
      <div class="d-flex justify-content-between">
        <div class="col-10">
          <img :src="item.img" style="border-radius: 4px;">
          <h5 class="card-text" style="font-size: 12px;">{{ item.title }}</h5>
          <h5 class="card-text">{{ item.code }}</h5>
          <h5 class="card-title mb-2" style="font-weight: 600;">Rs. {{ item.price }}</h5>
          <h5 class="card-title mb-2  text-muted" style="font-weight: 400;color:#767676;font-size:12px;">By {{item.storeName}}</h5>
        </div>
<!--        <div class="col-2">-->
<!--          <span class="float-end text-primary" style="font-weight: 500;font-size: 14px">-->
<!--          </span>-->
<!--        </div>-->
      </div>
      </div>
    </router-link>
  </div>
  <app-footer class="mt-5"/>
</template>

<script>
import AppHeader from '@/components/AppHeader.vue'
import api from '@/storage/api'
import { useRoute } from 'vue-router'
import { getLoggedInUser } from '@/storage/auth.module'
import Mixpanel from 'mixpanel-browser'
import AppFooter from '@/components/AppFooter.vue'
Mixpanel.init(process.env.VUE_APP_MIXPANEL_TOKEN)
export default {
  name: 'Search',
  components: {
    AppFooter,
    AppHeader
  },
  data () {
    return {
      userId: getLoggedInUser().userId,
      userRole: getLoggedInUser().userRole,
      userToken: getLoggedInUser().userToken,
      query: '',
      items: []
    }
  },
  created () {
    this.query = this.$route.query.keyword
    Mixpanel.track('Search Page Viewed')
    Mixpanel.track('Searched Keyword', {
      keyword: this.query
    })
    this.searchItems()
  },
  methods: {
    searchItems () {
      const route = useRoute()
      api.get(`/items/search/query/${this.query}?uuid=${this.userToken}`)
        .then(response => response.data)
        .then(data => {
          this.items = data
        })
        .catch(error => console.error(error))
    }
  }
}
</script>

<style scoped>

</style>
