<template>
  <app-header title="Terms" showBackBtn/>
  <div class="mt-5">&nbsp;</div>
    <div class="container">
      <h1>Terms and Conditions</h1>
      <p>Welcome to our B2B e-commerce platform! By accessing our platform, you agree to the following terms and conditions:</p>
      <ol>
        <li>
          <strong>Responsibility for order delivery:</strong> Our platform provider is not responsible for any delay in the delivery of orders. The delivery of orders is the sole responsibility of the seller.
        </li>
        <li>
          <strong>Responsibility for resolving issues:</strong> Our platform provider is not responsible for resolving any issues between buyers and sellers. It is the responsibility of the buyer and seller to resolve any issues that may arise during the buying and selling process.
        </li>
        <li>
          <strong>No guarantee of fund transfer:</strong> Our platform provider does not assure guaranteed fund transfer within TAT every time. The fund transfer process is dependent on external factors and is subject to delays and technical issues.
        </li>
        <li>
          <strong>Trademark and copyright issues:</strong> Our platform provider is not liable for any trademark and copyright issues of the sellers. Any dispute regarding trademark and copyright issues will be taken care of by the sellers directly.
        </li>
        <li>
          <strong>Quality of products:</strong> Our platform provider is not liable for the quality of the products sold on the platform. The sellers are responsible for ensuring the quality of their products.
        </li>
        <li>
          <strong>Modification of terms:</strong> Our platform provider reserves the right to modify these terms and conditions at any time without notice. It is the responsibility of the buyer and seller to check the terms and conditions periodically for any changes.
        </li>
        <li>
          <strong>Termination of services:</strong> Our platform provider reserves the right to terminate the services of any buyer or seller for any reason without notice.
        </li>
        <li>
          <strong>Governing law:</strong> These terms and conditions are governed by and construed in accordance with the laws of the jurisdiction where the platform is operated.
        </li>
        <li>
          <strong>Dispute resolution:</strong> Any dispute arising from these terms and conditions will be resolved through arbitration in accordance with the rules of the arbitration association in the jurisdiction where the platform is operated.
        </li>
      </ol>
      <p>By accessing our B2B e-commerce platform, you agree to the above terms and conditions. If you do not agree to these terms and conditions, you are not permitted to access or use our platform.</p>
    </div>
</template>

<script>
import AppHeader from '@/components/AppHeader.vue'

export default {
  name: 'Terms',
  components: {
    AppHeader
  }
}
</script>

<style scoped>

</style>
