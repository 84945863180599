<template>
  <splash-screen v-if="loading" />
  <app-header title="My wallet"/>
  <div class="mt-4">&nbsp;
  </div>
  <div class="mt-2 desc-card">
    <div class="card-footer card-footer-info" style="text-align: left;">
      Your bank account will be credited within 24-48hrs once approved.
    </div>
  </div>
  <div id="myDIV" class="modal-open==false">
  <router-link class="" :to="'/wallet/history'">
  <div class="card card-navigation product-card mt-2 mb-2 mx-3" style="height: 120px;">
    <div class="d-flex justify-content-between">
      <div class="col-8">
        <img src="../../assets/static/media/money-with-wings.gif" style="height: 80px;width: 80px;">
        <h5 class="card-title mb-1 mt-3" style="font-weight: 600;color:#10985D;font-size:24px;">
<!--          Rs. {{ wallet.balance - wallet.pendingRequestAmount }}-->
          Rs. {{ wallet.balanceAccured }}
        </h5>
        <h5 class="card-text" style="font-size: 12px;">Available Balance</h5>
<!--        <p class="card-text text-muted">{{ formatDate(wallet.lastModifiedDate) }}</p>-->
      </div>
      <div class="col-2">
          <span class="float-end text-primary" style="font-weight: 500;font-size: 14px">
          </span>
      </div>
    </div>
  </div>
  </router-link>
  <div class="text-center mb-2">
    <span class="secure"><i class="bi bi-shield-fill-check"></i> 100% safe and secure on eSetu.</span>
  </div>

<!--  <div v-if="noRecordFound" class="container text-center mt-5">-->
<!--    <img src="../../assets/static/media/ghost.gif" style="height:200px;">-->
<!--    <h1>No Records Found</h1>-->
<!--    <p>There is no record to display.</p>-->
<!--  </div>-->
<!--    <p class="text-muted">{{ userId }} {{ userRole }} {{ userToken }}</p>-->
  <div class="d-flex justify-content-between mt-3">
    <div class="dropdown mb-3 px-2">
      <button class="mx-2 btn btn-secondary  btn-sm dropdown-toggle" style="padding:5px 10px;" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
        Request: {{selectedOption}}
      </button>
      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <li><a class="dropdown-item selected" href="#" @click="selectOption('ALL')">All</a></li>
        <li><a class="dropdown-item" href="#" @click="selectOption('PENDING')">Pending</a></li>
        <li><a class="dropdown-item" href="#" @click="selectOption('APPROVED')">Approved</a></li>
        <li><a class="dropdown-item" href="#" @click="selectOption('REJECTED')">Rejected</a></li>
      </ul>
    </div>
    <a class="card-action-link mx-3 mt-2" href="#" @click="showRequestModalBox()">
      <span v-if="vpa==null">Add Bank Account</span>
      <span v-else>Bank Transfer</span>
    </a>
<!--    <a class="card-action-link mx-3 mt-2" href="#"  data-bs-toggle="modal" data-bs-target="#exampleModal" @click="openModal">-->
<!--      <span v-if="vpa==null">Add Bank Account</span>-->
<!--      <span v-else>Bank Transfer</span>-->
<!--    </a>-->
  </div>
    <div class="container text-center mt-5 mb-5" v-if="filteredRequests.length===0">
<!--      <img src="../../assets/static/media/confetti-ball.gif" style="height:100px;">-->
      <h4 class="mt-3">No pending requests!</h4>
      <p>You can order new products.</p>
      <router-link class="btn-secondary-orange btn mb-5" to="/home">Order Now</router-link>
    </div>
  <div class="card avatar-card tracker-card card-navigation mb-2 mx-3" v-for="request in filteredRequests" :key="request.id">
    <router-link class="" :to="'/wallet/request?id='+ request.id ">
    <div class="d-flex justify-content-between">
      <h6 style="font-weight: 400;">Rs. {{ request.amount }}<br/>
        <span class="text-muted">ID# {{ request.code }}</span><br/>
<!--        <span class="text-muted">Request by {{ request.requesterUserId }}</span><br/>-->
        <span class="text-muted">{{ formattedDate(request.createdDate) }}</span>
      </h6>
<!--      <h5 class="outward">{{ request.walletRedeemRequestStatus }}</h5>-->
      <div class="card-subtitle-badge"><span class="badge" style="color:#2d2d2d;background: #efefef;">{{ request.walletRedeemRequestStatus }}</span></div>
    </div>
    </router-link>
  </div>
  </div>
  <!-- Modal -->

    <div v-show="showRequestModal">
      <div class="modal" tabindex="-1" role="dialog" ref="modal">
<!--  <div class="modal" v-if="showModal" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">-->
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Bank Transfer</h5>
<!--          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>-->
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="hideRequestModalBox"></button>
        </div>
        <div class="modal-body">
          <div v-if="vpa==null">
          <form @submit.prevent="addBankDetails(userId)">
            <div class="form-floating mb-1">
              <input v-model="myvpa" type="text" class="form-control" placeholder="vpa" id="vpa" required>
              <label for="vpa">UPI ID</label>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary col-12">Add bank details</button>
            </div>
          </form>
          </div>
          <div v-else>
            <p>Your funds will be credited to <b>{{vpa}}</b></p>
            <div v-if="!wallet.pendingRequestAmount==0" class="mt-2 desc-card" style="box-shadow: none;">
            <div class="card-footer card-footer-info" style="text-align: left;">
              📣 At a time one request can be in <b>PENDING</b>. Once it's approved, you will be able to raise new!
            </div>
            </div>
            <p v-if="wallet.pendingRequestAmount==0">Wallet balance is <b>Rs. {{wallet.balance}}</b></p>
            <form v-if="wallet.pendingRequestAmount==0" @submit.prevent="raiseBankTransferRequest(wallet.id)">
              <div class="form-floating mb-1">
                <input v-model="transferAmount" type="text" class="form-control" placeholder="vpa" id="vpa" required>
                <label for="vpa">Transfer amount</label>
              </div>
              <div class="modal-footer">
                <button :disabled="!canRedeem" type="submit" class="btn btn-primary col-12">Raise a request</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
    </div>
  <nav-bottom></nav-bottom>
<!--  <app-footer class="mt-5"/>-->
</template>

<script>
import AppHeader from '@/components/AppHeader.vue'
import axios from 'axios'
import { getLoggedInUser } from '@/storage/auth.module'
import api from '@/storage/api'
import moment from 'moment'
import SplashScreen from '@/components/SplashScreen.vue'
import Mixpanel from 'mixpanel-browser'
import NavBottom from '@/components/NavBottom.vue'
Mixpanel.init(process.env.VUE_APP_MIXPANEL_TOKEN)

export default {
  name: 'Wallet',
  components: {
    NavBottom,
    AppHeader,
    SplashScreen
  },
  data () {
    return {
      loading: true,
      wallet: [],
      transactions: [],
      redeemRequests: [],
      timestamp: '',
      userId: getLoggedInUser().userId,
      userRole: getLoggedInUser().userRole,
      userToken: getLoggedInUser().userToken,
      vpa: '',
      myvpa: '',
      noRecordFound: false,
      transferAmount: 0,
      pendingAmount: 0,
      selectedOption: 'PENDING',
      showRequestModal: false
    }
  },
  mounted () {
    // axios.get(`http://localhost:8080/wallet?uuid=${this.userToken}`)
    Mixpanel.track('Wallet Page Viewed')
    this.fetchWallet()
    this.fetchRedeemRequest()
    this.fetchBankDetails(this.userId)
  },
  computed: {
    filteredRequests () {
      let filtered = this.redeemRequests
      if (this.selectedOption === 'ALL') {
        return filtered
      }
      if (this.selectedOption) {
        filtered = filtered.filter(redeemRequest => redeemRequest.walletRedeemRequestStatus === this.selectedOption)
        return filtered
      }
      return null
    },
    canRedeem () {
      if (this.transferAmount >= 100) {
        return true
      }
      return false
    }
  },
  methods: {
    showRequestModalBox () {
      this.showRequestModal = true
      this.$refs.modal.classList.add('show')
      this.$refs.modal.style.display = 'block'
      const myDiv = document.getElementById('myDIV')
      myDiv.classList.add('modal-open')
    },
    hideRequestModalBox () {
      this.showRequestModal = false
      this.$refs.modal.classList.remove('show')
      this.$refs.modal.style.display = 'none'
      const myDiv = document.getElementById('myDIV')
      myDiv.classList.remove('modal-open')
    },
    formatDate (date) {
      return moment(date).fromNow()
    },
    selectOption (option) {
      this.selectedOption = option
    },
    fetchWallet () {
      api.get(`/wallet?uuid=${this.userToken}`)
        .then(response => {
          this.wallet = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    fetchRedeemRequest () {
      api.get(`/wallet/requests?uuid=${this.userToken}`)
        .then(response => {
          this.redeemRequests = response.data
          this.loading = false
        })
        .catch(error => {
          console.log(error)
        })
    },
    async raiseBankTransferRequest (walletId) {
      try {
        const response = await api.post(`/wallet/redeem?uuid=${this.userToken}`, {
          // vpa: this.myvpa
          walletId: walletId,
          walletRedeemRequestStatus: 'PENDING',
          amount: this.transferAmount
        })
        this.fetchWallet()
        this.fetchRedeemRequest()
        this.hideRequestModalBox()
        Mixpanel.track('Bank Transfer Request', {
          status: 'Success',
          requestId: response.data.id,
          amount: this.transferAmount
        })
      } catch (error) {
        console.log(error)
        Mixpanel.track('Bank Transfer Request', { status: 'Failure', errorCode: error.code, errorMessage: error.message })
      }
    },
    formattedDate (timestamp) {
      const date = new Date(timestamp)
      return `${date.toLocaleDateString()}`
    },
    async addBankDetails (userId) {
      try {
        // const response = await axios.post(`http://localhost:8080/wallet/vpa?uuid=${this.userToken}`, {
        const response = await api.post(`/wallet/vpa?uuid=${this.userToken}`, {
          vpa: this.myvpa
        })
        this.myvpa = ''
        this.fetchBankDetails(userId)
        this.hideRequestModalBox()
        Mixpanel.track('VPA configuration', {
          status: 'Success'
        })
      } catch (error) {
        console.log(error)
        Mixpanel.track('VPA configuration', { status: 'Failure', errorCode: error.code, errorMessage: error.message })
      }
    },
    async fetchBankDetails (userId) {
      try {
        // const response = await axios.get(`http://localhost:8080/wallet/vpa?uuid=${this.userToken}`)
        const response = await api.get(`/wallet/vpa?uuid=${this.userToken}`)
        this.vpa = response.data.vpa
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style scoped>
.modal-dialog {
  position: fixed;
  bottom: 0;
  left: 0%;
  right: 0%;
  transform: translate(-50%, -50%);
}
.modal-open {
  filter: grayscale(100%) opacity(30%) blur(4px);
}
.nav-icon .nav-pills .nav-link.active, .nav-pills .show > .nav-link{
  background: #F6F6F6;
}
</style>
