<template>
  <div class="container">
    <div class="row">
      <div class="d-none d-md-block d-lg-block text-center mt-5">
        <div class="container">
          <div class="text-center mb-2">
            <img alt="eSetu" class="rounded-circle rotate-image" src="./assets/icon-72x72.svg">
          </div>
        </div>
        <h5>Please open this link on your Mobile.</h5>
        <div class="container">
          <div class="text-center mt-4">
            <p>Scan this QR code from your <br/><b>phone camera</b></p>
            <img alt="eSetu QR" src="./assets/web-qr.png" style="height: 200px;width: 200px;">
          </div>
        </div>
      </div>
      <div class="d-block d-md-none d-lg-none">
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
