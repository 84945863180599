<template>
  <app-header title="Wallet history" showBackBtn/>
  <div class="mt-5">&nbsp;</div>
    <div v-if="noRecordFound" class="container text-center mt-5">
      <img src="../../assets/static/media/ghost.gif" style="height:200px;">
      <h1>No Records Found</h1>
      <p>There is no record to display.</p>
    </div>
  <div class="card avatar-card tracker-card mb-2 mx-3" v-for="txn in transactions" :key="txn.id">
    <div class="d-flex justify-content-between">
      <span class="avatar avatar-xl rounded-circle avatar-whitebg">
              <i class="bi bi-arrow-up-right outward" v-show="txn.walletTxnType == 'DEBIT'"></i>
              <i class="bi bi-arrow-down-left inward" v-show="txn.walletTxnType == 'CREDIT'"></i>
            </span>
      <h5 class="card-title">
        <span style="font-size: 12px;font-weight: 600;">{{ txn.description }}</span><br/>
        <span class="text-muted" v-if="txn.orderId">Order# {{ txn.code }}</span>
        <span class="text-muted" v-if="txn.requestId">Request# {{ txn.code }}</span><br/>
        <span class="text-muted">{{ formattedDate(txn.createdDate) }}</span>
      </h5>
      <h5 class="outward" v-show="txn.walletTxnType == 'DEBIT'">- Rs. {{ txn.amount }}</h5>
      <h5 class="inward" v-show="txn.walletTxnType == 'CREDIT'">+ Rs. {{ txn.amount }}</h5>
    </div>
  </div>
</template>

<script>
import AppHeader from '@/components/AppHeader.vue'
import { useRoute } from 'vue-router'
import api from '@/storage/api'
import { getLoggedInUser } from '@/storage/auth.module'
import Mixpanel from 'mixpanel-browser'
Mixpanel.init(process.env.VUE_APP_MIXPANEL_TOKEN)
export default {
  name: 'WalletTransactions',
  components: {
    AppHeader
  },
  data () {
    return {
      userId: getLoggedInUser().userId,
      userRole: getLoggedInUser().userRole,
      userToken: getLoggedInUser().userToken,
      transactions: [],
      timestamp: '',
      noRecordFound: false
    }
  },
  mounted () {
    Mixpanel.track('Wallet History Page Viewed')
    this.fetchWalletTxn()
  },
  methods: {
    fetchWalletTxn () {
      const route = useRoute()
      api.get(`/wallet/transactions?uuid=${this.userToken}`)
        .then(response => response.data)
        .then(data => {
          this.transactions = data
          if (this.transactions.length > 0) {
            this.noRecordFound = false
          } else {
            this.noRecordFound = true
          }
        })
        .catch(error => {
          console.log(error)
          this.noRecordFound = true
        })
    },
    formattedDate (timestamp) {
      const date = new Date(timestamp)
      return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
    }
  }
}
</script>

<style scoped>

</style>
