import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import storage from './storage'
import './styles/styles.scss'
import 'vue-select/dist/vue-select.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import '@popperjs/core/dist/umd/popper.min.js'
import './registerServiceWorker'
import 'vue3-toastify/dist/index.css'
// import VueLazyload from 'vue-lazyload'
// import InfiniteLoading from 'vue-infinite-loading'
import InfiniteLoading from 'v3-infinite-loading'
import 'v3-infinite-loading/lib/style.css'

// import Mixpanel from 'mixpanel-browser'
// import 'bootstrap/dist/js/bootstrap.bundle.min.js'
// import vSelect from 'vue-select'
// import 'bootstrap/dist/css/bootstrap.min.css'

// createApp(App).use(router).mount('#app')
const app = createApp(App)
app.use(router)
app.use(storage)
app.component('infinite-loading', InfiniteLoading)
// app.use(VueLazyload)
// app.component('InfiniteLoading', InfiniteLoading)
app.mount('#app')

// app.directive('theme', {
//   mounted (el, binding) {
//     if (binding.value === 'primary') {
//       el.style.color = 'red'
//     } else if (binding.value === 'secondary') {
//       el.style.color = 'green'
//     } else if (binding.value === 'tertiary') {
//       el.style.color = 'blue'
//     } else {
//       el.style.color = 'black'
//     }
//   }
// })
