<template>
  <div class="nav-icon fixed-bottom" style="background: #F6F6F6;box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;border-top: 0.5px solid #cecece;">
    <nav class="nav nav-pills nav-fill" style="background: #F6F6F6;padding:5px;">
      <router-link to="/home" class="nav-link" :class="{ 'active': $route.path === '/home' }"><i class="fa-solid fa-home"></i><br/>Home</router-link>
      <router-link to="/order" class="nav-link" :class="{ 'active': $route.path === '/order' }"><i class="fa-solid fa-table-list"></i><br/>Orders</router-link>
      <router-link v-if="userRole!=='MERCHANT'" to="/cart" class="nav-link" :class="{ 'active': $route.path === '/cartnew' }"><i class="fa-solid fa-cart-shopping"></i><span class="badge rounded-pill badge-notification bg-danger" v-if="savedCartItems.length>=1">{{savedCartItems.length}}</span><br/>Cart
      </router-link>
      <router-link to="/wallet" class="nav-link" :class="{ 'active': $route.path === '/wallet' }"><i class="fa-solid fa-wallet"></i><br/>Wallet</router-link>
      <router-link to="/account" class="nav-link" :class="{ 'active': $route.path === '/account' }"><i class="fa-solid fa-user"></i><br/>Account</router-link>
    </nav>
  </div>
</template>

<script>
import { getLoggedInUser } from '@/storage/auth.module'
import { getCart } from '@/storage/cart.module'

export default {
  name: 'NavBottom',
  data () {
    return {
      userId: getLoggedInUser().userId,
      userRole: getLoggedInUser().userRole,
      userToken: getLoggedInUser().userToken,
      savedCartItems: getCart().cartItems,
      savedCartValue: getCart().cartValue,
      savedCartWeight: getCart().cartWeight,
      savedCartShippingCost: getCart().cartShippingCost
    }
  }
}
</script>
<style scoped>
.nav-icon .nav-pills .nav-link.active, .nav-pills .show > .nav-link{
  background: #F6F6F6;
}
</style>
