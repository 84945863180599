<template>
  <splash-screen v-if="loading" />
  <app-header :wa-number="store.waNumber" :mobile="store.mobile" :wa-msg="'My query is: '" :title="itemDetails.code" showBackBtn showContactCta/>
  <div class="mt-4">&nbsp;</div>
  <div class="mt-2">
    <div class="px-3 py-2 notice-info text-center" style="text-align: left;background: #FFF5D9;font-weight: 600;font-size: 12px;">
      <img src="../../assets/static/media/bell.gif" style="height:20px;"> Tap "Download Images" & share with your customers
    </div>
  <div class="card col-12 catalog-card" style="border-radius: 0;">
      <div class="text-center">
        <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
          <div class="carousel-indicators">
            <button v-for="(image, index) in itemDetails.imgs" :key="index" type="button" :data-bs-target="'#carouselExampleIndicators' + index" :data-bs-slide-to="index" :class="index === 0 ? 'active' : ''" :aria-current="index === 0 ? 'true' : 'false'" :aria-label="'Slide ' + (index + 1)"></button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item" :class="index === 0 ? 'active' : ''" v-for="(image, index) in itemDetails.imgs" :key="index">
              <img :src="image.img.replace('w_200,h_200,c_thumb/', 'w_800,h_800,c_thumb/')" class="d-block w-100 h-100" alt="..." style="border-radius: 10px;">
            </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
      <p class="product-title mt-2 mb-1" style="font-weight: 500;">{{ itemDetails.code }}: {{ itemDetails.title }}</p>
      <router-link :to="'/store/' + store.handler" class="mt-0 mb-2 card-action-link" style="font-weight: 600;cursor: pointer;text-transform: none;">By {{ store.displayName }} <span class="text-black-50">({{ store.handler }})</span></router-link>
    <div class="d-flex justify-content-between">
      <p class="product-cp mt-0 mb-0" style="font-size: 18px;">Rs. {{ itemDetails.displayPrice }}</p>
      <div class="nav nav-pills nav-customisation">
        <div class="nav-item" >
      <a target="_blank" class="nav-link shining-effect" @click="downloadImage(itemDetails.code, itemDetails.imgs)" style="font-weight: 600;cursor: pointer;background: #FAA011;color:#FFF;border: none;">Download images</a>
      </div>
      </div>
    </div>
  </div>
  </div>
  <div v-if="colors().length>=1">
  <div class="container mb-0">
    <div class="card desc-card tracker-card mb-1" style="border-radius: 8px 8px 0 0;background: linear-gradient(rgb(226, 238, 248) 0%, rgb(247, 244, 250) 100%);">
      <h5 class="card-title-uppercase mb-1">Select Color <img v-if="!selectedColor" src="../../assets/static/media/backhand_index_pointing_left.gif" style="height:30px;"></h5>
      <div class="row px-2">
        <ul class="nav nav-pills nav-customisation">
          <li class="nav-item px-1 py-1" v-for="(color, index) in colors()" :value="color" :key="index">
            <a class="nav-link" :class="{ 'active': color === selectedColor}" aria-current="page" @click="updateSelectedColor(color)">{{ getColorDisplayName(color) }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="container" v-if="selectedColor">
    <div class="card desc-card tracker-card mb-0" style="border-radius: 0;background: rgb(247, 244, 250) 100%;">
      <h5 class="card-title-uppercase">Select Size <img v-if="!selectedSize" src="../../assets/static/media/backhand_index_pointing_left.gif" style="height:30px;"></h5>
      <div v-if="selectedColor">
        <ul class="nav nav-pills nav-customisation">
          <li class="nav-item px-1 py-1" v-for="size in availableSizes()" :key="size">
<!--            <a class="nav-link" :class="{ 'active': size === selectedSize }" aria-current="page" @click="selectedSize = size">{{ size }}</a>-->
            <a class="nav-link" :class="{ 'active': size === selectedSize }" aria-current="page" @click="updateSelectedSize(size)">{{ size }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
    <div class="container" v-if="selectedSize && userRole!='MERCHANT' && userToken!=null">
      <div class="card desc-card tracker-card mt-1" style="border-radius: 0 0 8px 8px;background:linear-gradient(rgb(247, 244, 250) 0%, rgb(226, 238, 248) 100%);">
        <h5 class="card-title-uppercase mb-1">Select Qty <img src="../../assets/static/media/backhand_index_pointing_left.gif" style="height:30px;"></h5>
    <div class="dropdown mt-1" >
      <button class=" btn btn-secondary  btn-sm dropdown-toggle" style="padding:5px 10px;" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
        Select: {{selectedQty}}
      </button>
      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <li><a class="dropdown-item selected" @click="selectOption(1)">1</a></li>
        <li><a class="dropdown-item" @click="selectOption(2)">2</a></li>
        <li><a class="dropdown-item" @click="selectOption(3)">3</a></li>
        <li><a class="dropdown-item" @click="selectOption(4)">4</a></li>
        <li><a class="dropdown-item" @click="selectOption(5)">5</a></li>
        <li><a class="dropdown-item" @click="selectOption(6)">6</a></li>
        <li><a class="dropdown-item" @click="selectOption(7)">7</a></li>
        <li><a class="dropdown-item" @click="selectOption(8)">8</a></li>
        <li><a class="dropdown-item" @click="selectOption(9)">9</a></li>
        <li><a class="dropdown-item" @click="selectOption(10)">10</a></li>
      </ul>
    </div>
    </div>
    </div>
  </div>
  <div class="container mt-3 mb-2">
    <div class="card desc-card tracker-card">
      <div class="d-flex justify-content-between">
        <h5 class="card-title-uppercase mb-3">Product Description</h5>
        <a target="_blank" class="card-action-link" @click="copyDescription(itemDetails.description, itemDetails.setuCode, itemDetails.code)" style="font-weight: 600;cursor: pointer;">Copy</a>
      </div>
      <p class="text-muted">Product weight: ~{{itemDetails.finalWeight/1000}} KG</p>
      <p :class="{ 'collapsed': collapsed }" class="card-text" style="white-space: pre-line">{{ itemDetails.description }}</p>
      <a target="_blank" class="card-action-link" style="font-weight: 600;cursor: pointer;" v-if="shouldShowReadMore" @click="collapsed = !collapsed">{{ collapsed ? 'Read more' : 'Read less' }}</a>
    </div>
  </div>
  <div class="container mb-2" v-if="isStoreOwner && userToken!=null">
  <div class="card desc-card tracker-card">
<!--    <h5 class="card-title-uppercase mb-3">Product Size</h5>-->
    <div class="d-flex justify-content-between">
      <h5 class="card-title-uppercase mb-3">Configure Color & Size</h5>
      <a v-if="userRole!=='CUSTOMER'" class="card-action-link" href="#"  data-bs-toggle="modal" data-bs-target="#exampleModal">Add new</a>
    </div>
    <div v-for="item in itemDetails.sku" :key="item.id" >
  <div class="card product-card mt-2 mb-2 mx-3" style="min-height: 80px;box-shadow:none;border: 0.5px solid #cecece !important;">
    <div class="d-flex justify-content-between" >
      <div class="col-8">
        <img :alt="item.code" :src=item.img style="height: 50px;width: 50px;" :class="{ 'grayscale': !item.active }">
        <div  v-if="item.color"  class="card-text" style="margin-left:60px;">
          Color: {{getColorDisplayName(item.color)}}&nbsp;<span class="badge" :style="'background:'+ getColorCode(item.color)" style="color:#2d2d2d;margin-top: 2.5px;height: 11px;width: 10px;position: absolute;">&nbsp;</span>
        </div>
        <p class="card-text mb-2" style="margin-left:60px;">Size: {{ item.size }}</p>
        <h6 class="card-subtitle" style="margin-left:60px;">Rs. {{ item.price }}</h6>
      </div>
      <div class="col-2" v-if="isStoreOwner || userRole=='ADMIN'">
          <span class="float-end">
            <div class="form-check form-switch">
              <input v-model="item.active" @click="updateSku(!item.active, item.id)" class="form-check-input float-end" type="checkbox" id="flexSwitchCheckChecked" checked >
            </div>
          </span>
      </div>
    </div>
  </div>
    </div>
  </div>
  </div>
  <div class="container mb-2" v-if="isStoreOwner || userRole=='ADMIN'">
    <button v-if="!itemDetails.active" type="submit" class="btn btn-primary w-100" @click="updateItem(true, itemDetails.id)">Activate</button>
    <button v-if="itemDetails.active" type="submit" class="btn btn-danger w-100" @click="updateItem(false, itemDetails.id)">Inactivate</button>
  </div>
  <nav v-if="selectedSize && userRole!='MERCHANT'" class="navbar fixed-bottom navbar-light bg-light btn-nav p-2 px-3" style="background: #06C270 !important;">
      <span class="w-48" style="color: #FFF;">Rs. {{selectedQty * itemDetails.displayPrice }}<br />
      <span class="btn-footer-text" style="color: #FFF;">Total payable</span>
    </span>

    <button v-if="userToken" @click="addToCart(itemDetails)" class="btn btn-primary w-20" style="font-weight: 700;"><img src="../../assets/static/media/backhand_index_pointing_right.gif" style="height:30px;"> ADD TO CART</button>
<!--    <button class="btn btn-secondary-orange w-20" style="font-weight: 700;">LOGIN</button>-->
  </nav>

  <nav v-if="showHomeBtn && userToken==null" class="navbar fixed-bottom navbar-light bg-light btn-nav">
    <button type="submit" class="btn btn-primary w-100" @click="goback()">Go to Home</button>
  </nav>
  <app-footer class="mt-0"/>

  <div class="modal" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Customisation</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="saveCustomisationForm">
            <div class="form-group">
              <label for="select-options">Select Color</label>
              <select id="select-options" class="form-control" v-model="selectedColorOption" style="height: 50px;">
                <option selected value="">Select</option>
                <option v-for="option in colorOptions" :key="option.value" :value="option.value">{{ option.name }}</option>
              </select>
            </div>
            <div class="row mb-3"></div>
            <div class="form-group">
              <label for="select-options">Select size</label>
              <select id="select-options" class="form-control" v-model="selectedSizeOption" style="height: 50px;">
                <option selected value="">Select</option>
                <option v-for="option in sizeOptions" :key="option.value" :value="option.value">{{ option.text }}</option>
              </select>
            </div>
            <div class="modal-footer">
              <button :disabled="!isCustomisationFormValid" type="submit" data-bs-dismiss="modal" class="btn btn-primary col-12">Add</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppHeader from '@/components/AppHeader.vue'
import { createWebHistory, useRoute, useRouter } from 'vue-router'
import api from '@/storage/api'
import { getLoggedInUser } from '@/storage/auth.module'
import Mixpanel from 'mixpanel-browser'
import SplashScreen from '@/components/SplashScreen.vue'
import color from '@/data/color'
import swal from 'sweetalert2'
import { toast } from 'vue3-toastify'
import size from '@/data/size'
import { mapActions, mapState, mapGetters } from 'vuex'
import router from '@/router'
import { setCart, getCart, resetCart, updateCart } from '@/storage/cart.module'
import AppFooter from '@/components/AppFooter.vue'

Mixpanel.init(process.env.VUE_APP_MIXPANEL_TOKEN)

export default {
  name: 'ItemDetail',
  components: {
    AppFooter,
    AppHeader,
    SplashScreen
  },
  data () {
    return {
      itemDetails: [],
      loading: true,
      userId: getLoggedInUser().userId,
      userRole: getLoggedInUser().userRole,
      userToken: getLoggedInUser().userToken,
      store: [],
      showHomeBtn: false,
      collapsed: true,
      selectedSizeOption: '',
      sizeOptions: size,
      selectedColorOption: '',
      colorOptions: color,
      formCustomisationSaved: false,
      selectedColor: null,
      selectedSize: null,
      selectedQty: 1,
      selectedSku: '',
      savedStoreId: getCart().storeId,
      savedCartItems: getCart().cartItems,
      savedCartValue: getCart().cartValue,
      savedCartWeight: getCart().cartWeight,
      savedCartShippingCost: getCart().cartShippingCost,
      message: 'SELECT COLOR',
      isStoreOwner: false,
      image: 'https://res.cloudinary.com/lf/image/upload/w_800,h_800,c_thumb/lf/dzxbwtuvx4hfvcluav6l'
    }
  },
  async created () {
    Mixpanel.track('Product Page Viewed')
    const history = createWebHistory()
    if (history.state.back === null) {
      this.showHomeBtn = true
    }
    const route = useRoute()
    await api.get(`/items/get/${route.params.itemId}?uuid=${this.userToken}`)
      .then(response => response.data)
      .then(data => {
        this.itemDetails = data
        // this.selectedColor = this.itemDetails.sku[0].color
        this.itemDetails.img = this.itemDetails.img.replace('w_200,h_200,c_thumb/', '')
        this.loading = false
      }).catch(error => console.error(error))
    console.log(this.itemDetails)
    await api.get(`/store/id/${this.itemDetails.storeId}`)
      .then(response => response.data)
      .then(data => {
        this.store = data
      }).catch(error => console.error(error))
    // this.viewItem()
    // this.fetchStore()
    const metaTags = [
      { name: 'title', content: this.itemDetails.code + ' - ' + this.itemDetails.category + ' - ' + this.store.displayName },
      { name: 'description', content: 'Shop for ' + this.itemDetails.code + ' - ' + this.itemDetails.category + ' on ' + this.store.displayName + ' - High-quality products at great prices.' },
      { name: 'og:title', content: this.itemDetails.code + ' - ' + this.itemDetails.category + ' - ' + this.store.displayName },
      { name: 'og:description', content: 'Shop for ' + this.itemDetails.code + ' - ' + this.itemDetails.category + ' on ' + this.store.displayName + ' - High-quality products at great prices.' },
      { name: 'og:image', content: this.itemDetails.img },
      { name: 'og:url', content: 'https://app.esetu.in/store/' + this.store.handler + '/' + this.itemDetails.setuCode },
      { name: 'keywords', content: this.store.displayName + ', ' + this.itemDetails.code + ', ' + this.itemDetails.category + ', Online Shopping, Affordable Prices, High-Quality Products' }
    ]
    const setMetaTags = (tags) => {
      tags.forEach(tag => {
        const metaTag = document.createElement('meta')
        Object.keys(tag).forEach(key => {
          metaTag.setAttribute(key, tag[key])
        })
        document.head.appendChild(metaTag)
      })
    }
    setMetaTags(metaTags)
  },
  watch: {
    selectedSize: function (newVal, oldVal) {
      const skuId = this.itemDetails.sku.find(
        (sku) => sku.color === this.selectedColor && sku.size === newVal
      )
      if (skuId) {
        this.selectedSku = skuId.id
      } else {
        this.selectedSku = null
      }
    }
  },
  computed: {
    isCustomisationFormValid () {
      return this.selectedColorOption !== '' &&
        this.selectedSizeOption !== ''
    },
    ...mapState(['cartItems']),
    ...mapGetters(['cartItemsWithQuantities'])
  },
  mounted () {
    if (this.userRole === 'MERCHANT') {
      this.checkStoreOwner()
    }
  },
  methods: {
    ...mapActions(['addToSelectedItems', 'removeFromSelectedItems', 'clearCart']),
    clearStorage () {
      resetCart()
    },
    async downloadImage (code, imgs) {
      try {
        const imageUrls = [
          'https://res.cloudinary.com/lf/image/upload/w_100,h_800,c_thumb/lf/dzxbwtuvx4hfvcluav6l',
          'https://res.cloudinary.com/lf/image/upload/w_200,h_800,c_thumb/lf/dzxbwtuvx4hfvcluav6l',
          'https://res.cloudinary.com/lf/image/upload/w_300,h_800,c_thumb/lf/dzxbwtuvx4hfvcluav6l'
        ] // Replace with an array of your image URLs

        // Loop through each image URL and download it
        for (let i = 0; i < imgs.length; i++) {
          const imageUrl = imgs[i].img.replace('w_200,h_200,c_thumb/', '')
          const fileName = `${code}_${i + 1}.jpg`

          const response = await fetch(imageUrl)
          const blob = await response.blob()

          // Create a download link for the image and click it programmatically
          const url = URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.href = url
          link.download = fileName
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          URL.revokeObjectURL(url)

          // Delay before the next download request
          await new Promise((resolve) => setTimeout(resolve, 1500))
          toast.success('Image ' + (i + 1) + ' downloaded!')
        }
        Mixpanel.track('Item images downloaded', {
          'No. of images ': imgs.length
        })
        toast.success('All Images downloaded successfully!')
      } catch (error) {
        console.error(error)
      }
    },
    async checkStoreOwner () {
      await api.get('/store/my?uuid=' + this.userToken)
        .then(response => response.data)
        .then(data => {
          if (data.id === this.itemDetails.storeId) {
            this.isStoreOwner = true
          }
        })
        .catch(error => {
          console.error(error)
        })
    },
    async addToCart (item) {
      Mixpanel.track('Product added to cart', {
        'Store Id': this.itemDetails.storeId,
        'Sku Id': this.selectedSku,
        'Color ': this.selectedColor,
        'Size ': this.selectedSize,
        'Quantity ': this.selectedQty
      })
      if (this.itemDetails.storeId === Number(this.savedStoreId) || this.savedStoreId === null || this.savedStoreId === '') {
        const index = this.savedCartItems.findIndex(cartItem => cartItem.id === this.selectedSku)
        if (index > -1) {
          updateCart(this.itemDetails.storeId, getCart().cartItems, this.selectedSku, this.selectedQty)
          toast.success('Item added to the cart!')
          await router.push({ path: '/cart' })
        } else {
          const addItem = {
            img: item.imgs[0].img,
            weight: item.finalWeight,
            shippingCost: item.shippingCost,
            code: item.code,
            price: item.displayPrice,
            id: this.selectedSku,
            color: this.selectedColor,
            size: this.selectedSize,
            quantity: this.selectedQty
          }
          setCart(this.itemDetails.storeId, addItem, getCart().cartItems, getCart().cartValue, getCart().cartWeight, getCart().cartShippingCost)
          toast.success('Item added to the cart!')
          await router.push({ path: '/cart' })
        }
      } else {
        const result = await swal.fire({
          title: 'Replace cart items?',
          text: 'You cart contains items from different store. Do you want to discard the selection and add this item?',
          icon: 'warning',
          showCloseButton: true,
          showConfirmButton: true,
          confirmButtonText: 'Yes, add this item!',
          confirmButtonColor: '#06C270',
          showDenyButton: true,
          denyButtonText: 'Go to cart',
          denyButtonColor: '#767676',
          reverseButtons: true
        })
        if (result.isConfirmed) {
          resetCart()
          const addItem = {
            img: item.imgs[0].img,
            weight: item.finalWeight,
            shippingCost: item.shippingCost,
            code: item.code,
            price: item.displayPrice,
            id: this.selectedSku,
            color: this.selectedColor,
            size: this.selectedSize,
            quantity: this.selectedQty
          }
          setCart(this.itemDetails.storeId, addItem, getCart().cartItems, getCart().cartValue, getCart().cartWeight, getCart().cartShippingCost)
          toast.success('Item added to the cart!')
          await router.push({ path: '/cart' })
        } else if (result.isDenied) {
          await router.push({ path: '/cart' })
        }
      }
    },
    updateSelectedColor (color) {
      this.selectedColor = color
      this.selectedSize = ''
      this.selectedQty = 1
      Mixpanel.track('Item color selected', {
        'Color ': color
      })
    },
    updateSelectedSize (size) {
      this.selectedSize = size
      Mixpanel.track('Item size selected', {
        'Size ': size
      })
    },
    selectOption (option) {
      this.selectedQty = option
      Mixpanel.track('Item qty selected', {
        'Qty ': option
      })
    },
    getColorCode (colorName) {
      const selectedColor = color.find(color => color.value === colorName)
      return selectedColor.code
    },
    getColorDisplayName (colorName) {
      const selectedColor = color.find(color => color.value === colorName)
      return selectedColor.name
    },
    // colors () {
    //   return [...new Set(this.itemDetails.sku.map((sku) => sku.color))]
    // },
    colors () {
      return [...new Set(this.itemDetails.sku.filter(sku => sku.color !== null).map(sku => sku.color))]
    },
    availableSizes () {
      const selectedSkus = this.itemDetails.sku.filter(
        (sku) => sku.color === this.selectedColor && sku.active === true
      )
      return selectedSkus.map((sku) => sku.size)
    },
    getItemDetails (itemCode) {
      api.get(`/items/get/${itemCode}?uuid=${this.userToken}`)
        .then(response => {
          this.itemDetails = response.data
          this.itemDetails.img = this.itemDetails.img.replace('w_200,h_200,c_thumb/', '')
          this.loading = false
        }).catch(error => console.error(error))
    },
    saveCustomisationForm () {
      const sku = {
        color: this.selectedColorOption,
        size: this.selectedSizeOption
      }
      api.post(`items/sku/${this.itemDetails.id}?uuid=${this.userToken}`, {
        ...sku
      }).then(response => {
        console.log(response)
        if (response.data === '') {
          toast.info('Customisation already exists!')
        } else {
          toast.success('Customisation has been added successfully!')
          this.getItemDetails(this.itemDetails.setuCode)
        }
        this.selectedColorOption = ''
        this.selectedSizeOption = ''
      }).catch(error => {
        console.error(error)
      })
    },
    shouldShowReadMore () {
      const lineHeight = parseInt(getComputedStyle(this.$el).lineHeight)
      const height = this.$el.querySelector('p').offsetHeight
      const numLines = height / lineHeight
      return numLines > 3
    },
    copyDescription (text, itemSetuCode, itemCode) {
      const textArea = document.createElement('textarea')
      textArea.value = text
      document.body.appendChild(textArea)
      textArea.select()
      document.execCommand('copy')
      textArea.remove()
      toast.info('Description copied!')
      Mixpanel.track('Item description copied', {
        'Item Setu Code': itemSetuCode,
        'Item Code': itemCode
      })
    },
    viewItem () {
      console.log('view item')
    },
    updateItem (isActive, id) {
      const data = {
        active: isActive
      }
      const route = useRoute()
      api.put(`/items/${id}?uuid=${this.userToken}`, data)
        .then(response => response.data)
        .then(data => {
          console.log(data)
          toast.success('Status has been changed!')
          this.getItemDetails(this.itemDetails.setuCode)
        })
        .catch(error => console.error(error))
    },
    updateSku (isActive, id) {
      const data = {
        active: isActive
      }
      const route = useRoute()
      api.put(`/items/sku/${id}?uuid=${this.userToken}`, data)
        .then(response => response.data)
        .then(data => {
          console.log(data)
          toast.success('Status has been changed!')
        })
        .catch(error => console.error(error))
    },
    fetchStore () {
      console.log('fetch store')
    },
    goback () {
      this.$router.push('/home')
    }
  }
}
</script>

<style scoped>
.product-details {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}
.grayscale {
   filter: grayscale(100%);
}
p.collapsed {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
.modal-dialog {
  position: fixed;
  bottom: 0;
  left: 0%;
  right: 0%;
  transform: translate(-50%, -50%);
}
.dropdown-toggle {
  background-color: #5E5FD9;
  border-color: #D1D1FC;
  color: #FFF;
}
</style>
<!--      <div>-->
<!--        <label>Select a color:</label>-->
<!--        <select v-model="selectedColor">-->
<!--          <option v-for="(color,index) in colors()" :value="color" :key="index">{{ color }}</option>-->
<!--        </select>-->
<!--        <label>Select a size:</label>-->
<!--        <select v-model="selectedSize">-->
<!--          <option v-for="(size,index) in availableSizes()" :value="size" :key="index">{{ size }}</option>-->
<!--        </select>-->
<!--      </div>-->
<!--      {{colors()}}-->
<!--      {{availableSizes()}}-->
<!--        <h2>Available Sizes for {{ selectedColor }}</h2>-->
<!--        <ul>-->
<!--          <li v-for="size in availableSizes()" :key="size">{{ size }}</li>-->
<!--        </ul>-->

<!--async addToCart (item) {-->
<!--if (this.itemDetails.storeId === this.$store.state.carts.storeId || this.$store.state.carts.storeId === '') {-->
<!--const index = this.$store.state.carts.cartItems.findIndex(cartItem => cartItem.id === this.selectedSku)-->
<!--if (index > -1) {-->
<!--this.$store.state.carts.cartItems[index].quantity += this.selectedQty-->
<!--this.$store.state.carts.cartValue += (item.displayPrice * this.selectedQty)-->
<!--this.$store.state.carts.cartWeight += (item.finalWeight * this.selectedQty)-->
<!--this.$store.state.carts.cartShippingCost += (item.shippingCost * this.selectedQty)-->
<!--toast.success('Item added to the cart!')-->
<!--updateCart(this.itemDetails.storeId, getCart().cartItems, this.selectedSku, this.selectedQty)-->
<!--await router.push({ path: '/cartnew' })-->
<!--} else {-->
<!--this.$store.state.carts.storeId = this.itemDetails.storeId-->
<!--const addItem = {-->
<!--img: item.imgs[0].img,-->
<!--weight: item.finalWeight,-->
<!--shippingCost: item.shippingCost,-->
<!--code: item.code,-->
<!--price: item.displayPrice,-->
<!--id: this.selectedSku,-->
<!--color: this.selectedColor,-->
<!--size: this.selectedSize,-->
<!--quantity: this.selectedQty-->
<!--}-->
<!--this.addToSelectedItems(addItem)-->
<!--setCart(this.itemDetails.storeId, addItem, getCart().cartItems, '', '', '')-->
<!--toast.success('Item added to the cart!')-->
<!--await router.push({ path: '/cartnew' })-->
<!--}-->
<!--} else {-->
<!--const result = await swal.fire({-->
<!--title: 'Replace cart time?',-->
<!--text: 'You cart contains items from different store. Do you want to discard the selection and add this item?',-->
<!--icon: 'warning',-->
<!--showCancelButton: true,-->
<!--confirmButtonText: 'Yes',-->
<!--cancelButtonText: 'No',-->
<!--reverseButtons: true-->
<!--})-->
<!--if (result.isConfirmed) {-->
<!--this.clearCart()-->
<!--this.$store.state.carts.storeId = this.itemDetails.storeId-->
<!--const addItem = {-->
<!--img: item.img,-->
<!--weight: item.finalWeight,-->
<!--shippingCost: item.shippingCost,-->
<!--code: item.code,-->
<!--price: item.displayPrice,-->
<!--id: this.selectedSku,-->
<!--color: this.selectedColor,-->
<!--size: this.selectedSize,-->
<!--quantity: this.selectedQty-->
<!--}-->
<!--this.addToSelectedItems(addItem)-->
<!--toast.success('Item added to the cart!')-->
<!--await router.push({ path: '/cartnew' })-->
<!--}-->
<!--}-->
<!--},-->
