<template>
  <div class="px-3 mt-4 mb-4">
<!--    <div class="container">-->
<!--      <div class="row">-->
<!--        <div class="col-3 mt-4"></div>-->
<!--        <div class="col-2 mt-2">-->
<!--          <img alt="eSetu" class="rounded-circle rotate-image" src="../../assets/icon-72x72.png">-->
<!--        </div>-->
<!--        <div class="col-5 mt-4">-->
<!--          <h1 class="py-2 mx-4" style="font-weight: 300;font-size: 50px;color:#FAA011;">e<span style="color: #4CCA5A;font-weight: 700;">S</span>etu</h1>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <div class="container">
      <div class="text-center mt-4">
        <img alt="eSetu" class="rounded-circle rotate-image" src="../../assets/icon-72x72.svg">
      </div>
    </div>
  </div>
    <div class="px-3 mb-4 text-center">
<!--      <h1 class="mx-4 my-4 mt-5" style="font-weight: 600;font-size: 40px;">Your business is accessible <span class="text-primary">anywhere</span> and <span class="text-primary">anytime</span></h1>-->
      <h1 class="mx-4 my-4" style="font-weight: 100;font-size: 30px;">eSetu's AI connects you to the <span class="text-primary" style="font-weight: 700">top suppliers</span> so that you can <br/><span style="font-weight: 700;">Sell More</span></h1>
<!--      <p class="text-black-50 mx-2">eSetu takes away the complexity from day to day business operation so that you can sellmore! <img src="../../assets/static/media/party-propper.gif" class="align-bottom" style="height:30px;"></p>-->
    </div>
  <div class="text-center mt-4" id="getstarted">
  <img src="../../assets/static/community.png">
  </div>
    <div class="text-center mt-2">
      <form @submit.prevent="getStarted">
      <div class="form-floating mx-4 mb-2">
        <input type="number" pattern="[0-9]*" :class="{'invalid': !isPhoneValid}" inputmode="numeric" class="form-control" placeholder="Mobile" id="phone" v-model="mobile" style="border-radius: 8px;" :required="true">
        <label for="name">Mobile</label>
      </div>
        <button class="btn-secondary-orange btn col-5" style="font-weight: 700;">Get started <span style="font-weight: 300;">- it's free</span></button>
        <!--      <router-link class="btn-secondary-orange btn col-5" to="/login" style="font-weight: 700;">Get started <span style="font-weight: 300;">- it's free</span></router-link>-->
      </form>
    </div>
  <div class="container mx-2 mt-5">
    <h3 style="font-weight: 700;">Welcome to eSetu community!</h3>
    <p class="text-black mb-5">We connect resellers to the top suppliers in India, providing a trustworthy platform that makes business transactions seamless and hassle-free.</p>
  </div>
  <div class="container mx-2 mt-5">
    <img style="height: 200px;" src="../../assets/static/contactus.png">
    <h3 style="font-weight: 700;">24x7 support team</h3>
    <p class="text-black">Our support team is always ready to assist you, and we offer secure payment options to protect your transactions.</p>
  </div>
  <div class="container mx-2 mt-5">
    <img style="height: 200px;" src="../../assets/static/easy.png">
    <h3 style="font-weight: 700;">One stop solution</h3>
    <p class="text-black">Say goodbye to the hassle of managing and tracking orders through WhatsApp or other complex systems. With eSetu, you can place and manage orders, track shipments, and keep up to date with your business performance all in one place. Our platform is designed to save you time and streamline your operations.</p>
  </div>
    <div class="container mt-4 py-4" style="background: #FFF;">
    <p class="text-black-50 mx-2">Join eSetu today and take advantage of our trustworthy platform to grow your business and reach new heights.</p>
    <div class="text-center">
      <a class="btn-primary btn col-3" href="#getstarted" style="font-weight: 500;">Join now</a>
    </div>
  </div>
<!--  <nav class="navbar fixed-bottom navbar-light bg-light">-->
<!--    <p class="mx-2">Text here...</p>-->
<!--  </nav>-->
  <div class="toast-container position-fixed top-0 text-center mb-3 mx-4" >
    <!-- error toast message -->
    <div class="toast align-items-center text-white bg-danger border-0" role="alert"
         :class="{ 'show': isShowErrorToast }">
      <div class="d-flex">
        <div class="toast-body">
          {{ errorMessage }}
        </div>
        <button type="button" class="btn-close me-2 m-auto" data-bs-dismiss="toast" aria-label="Close" @click.prevent="isShowErrorToast = false"></button>
      </div>
    </div>
  </div>
  <app-footer class="mt-0"/>
</template>
<script>
import api from '@/storage/api'
import { useRoute } from 'vue-router'
import Mixpanel from 'mixpanel-browser'
import router from '@/router'
import AppFooter from '@/components/AppFooter.vue'

Mixpanel.init(process.env.VUE_APP_MIXPANEL_TOKEN)

export default {
  components: {
    AppFooter
  },
  data () {
    return {
      user: [],
      mobile: null,
      errorMessage: '',
      isShowErrorToast: false
    }
  },
  created () {
    Mixpanel.track('Landing Page Viewed')
    const metaTags = [
      { name: 'description', content: "eSetu's AI connects you to the top suppliers so that you can Sell More. Join eSetu today and take advantage of our trustworthy platform to grow your business and reach new heights." },
      { property: 'og:title', content: 'eSetu - Connecting You to Top Suppliers in India' },
      { property: 'og:description', content: "eSetu's AI connects you to the top suppliers so that you can Sell More. Join eSetu today and take advantage of our trustworthy platform to grow your business and reach new heights." },
      // { property: 'og:image', content: '../../assets/icon-72x72.png' },
      { property: 'og:url', content: 'https://esetu.in' }
    ]
    const setMetaTags = (tags) => {
      tags.forEach(tag => {
        const metaTag = document.createElement('meta')
        Object.keys(tag).forEach(key => {
          metaTag.setAttribute(key, tag[key])
        })
        document.head.appendChild(metaTag)
      })
    }
    setMetaTags(metaTags)

    const route = useRoute()
    const utmSource = route.query.utm_source
    const utmMedium = route.query.utm_medium
    if (route.query.utm_source) {
      Mixpanel.register({
        utm_source: utmSource
      })
    }
    if (route.query.utm_medium) {
      Mixpanel.register({
        utm_medium: utmMedium
      })
    }
  },
  methods: {
    getStarted () {
      if (this.isFormValid) {
        Mixpanel.track('Get Started Clicked', {
          mobile: this.mobile
        })
        router.push({
          name: 'login'
        })
      } else {
        this.isShowErrorToast = true
        this.errorMessage = 'Please enter valid mobile number!'
      }
    }
  },
  computed: {
    isPhoneValid () {
      return /^[0-9]{10}$/.test(this.mobile)
    },
    isFormValid () {
      return this.isPhoneValid
    }
  }
}
</script>
<style scoped lang="scss">
.btn:hover{
  background: #FAA011;
}
</style>
