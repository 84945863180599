<template>
  <nav class="navbar navbar-light bg-light nav-top fixed-top">
    <div class="container-fluid">
<!--      <a v-if="!showBackBtn" href="#" class="navbar-brand">-->
<!--              <span href="#" class="avatar avatar-sm rounded-circle">-->
<!--&lt;!&ndash;                <span>{{ $store.getters['user/fullName'].charAt(0) }}</span>&ndash;&gt;-->
<!--&lt;!&ndash;                              <img alt="..." src="../assets/icon-72x72.png">&ndash;&gt;-->
<!--                <span>E</span>-->
<!--              </span>-->
<!--&lt;!&ndash;        <span class="nav-menu-txt">{{ $store.getters['user/fullName'] }}</span>&ndash;&gt;-->
<!--        <span class="nav-menu-txt" style="text-transform: none;font-size: 20px;">eSetu</span>-->
<!--      </a>-->
      <a v-if="showBackBtn" @click="goBack" class="py-2">
        <i class="fa-solid fa-arrow-left-long" style="color: #767676;"></i>
        <span class="text-start nav-menu-txt" style="margin-left:10px;font-weight: 700;">{{title}}</span>
      </a>
      <div v-if="!showBackBtn" class="py-2">
        <span class="text-center nav-menu-txt" style="margin-left:10px;font-weight: 700;">{{title}}</span>
      </div>
      <form v-if="showHomeCta" class="d-flex nav-right-action px-2">
        <div class="tab-selection">
          <ul class="nav nav-pills">
            <li class="nav-item" @click="mpEvent('call')">
              <router-link to="/home" class="nav-link" aria-current="page" style="background: #efefef;color:#999999;border: none;"><i class="fa-solid fa-home" style="color: #999999;font-size:14px;"></i> Home</router-link>
            </li>
          </ul>
        </div>
      </form>
      <form v-if="showContactCta" class="d-flex nav-right-action px-2">
        <div class="tab-selection">
          <ul class="nav nav-pills">
            <li class="nav-item" @click="mpEvent('call')">
              <a class="nav-link" aria-current="page" :href="'Tel:+'+mobile" style="background: #D1D1FC;color:#5E5FD9;border: none;"><i class="bi bi-telephone" style="color: #5E5FD9;"></i> Call</a>
            </li>
            <li class="nav-item" @click="mpEvent('whatsapp')">
              <a class="nav-link px-2" target="_blank" :href="'https://wa.me/'+waNumber+'?text=' + encodeURIComponent(waMsg)" style="background: #4ECB5D;border: none;"><img src="../assets/wa.png" class="align-bottom" style="height:24px;"></a>
            </li>
          </ul>
        </div>
      </form>
      <form v-if="showLogoutCta" class="d-flex nav-right-action">
        <div class="tab-selection">
          <ul class="nav nav-pills">
            <li class="nav-item">
              <a class="nav-link" v-on:click="logout()" style="background: #FFF;color:#DB4437;border: none;opacity: 50%;font-weight: 100;"><i class="bi bi-box-arrow-right" style="color:#DB4437;"></i> logout</a>
            </li>
          </ul>
        </div>
      </form>
    </div>
    </nav>
</template>

<script>
import { logoutUser } from '@/storage/auth.module'
import Mixpanel from 'mixpanel-browser'
import { createWebHistory, useRouter } from 'vue-router'
import router from '@/router'

export default {
  props: {
    title: {
      type: String,
      default: 'Title'
    },
    waMsg: {
      type: String,
      default: 'waMsg'
    },
    waNumber: {
      type: String,
      default: 'waNumber'
    },
    mobile: {
      type: String,
      default: 'mobile'
    },
    showBackBtn: {
      type: Boolean,
      default: false
    },
    showContactCta: {
      type: Boolean,
      default: false
    },
    showLogoutCta: {
      type: Boolean,
      default: false
    },
    showHomeCta: {
      type: Boolean,
      default: false
    }
  },
  setup () {
    function logout () {
      logoutUser()
    }
    return { logout }
  },
  methods: {
    mpEvent (event) {
      Mixpanel.track('Header CTA', {
        menu: this.title,
        event: event
      })
    },
    goBack () {
      // const router = useRouter()
      // router.back()
      this.$router.back()
    }
  }
}
</script>
<style lang="scss">
</style>
