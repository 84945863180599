export default [
  { value: 'WHITE', name: 'White', code: '#FFFFFF' },
  { value: 'BLACK', name: 'Black', code: '#000000' },
  { value: 'BEIGE', name: 'Beige', code: '#F5F5DC' },
  { value: 'ORANGE', name: 'Orange', code: '#FFA500' },
  { value: 'BLUE', name: 'Blue', code: '#0000FF' },
  { value: 'NAVYBLUE', name: 'Navy Blue', code: '#000080' },
  { value: 'YELLOW', name: 'Yellow', code: '#FFFF00' },
  { value: 'DARKPINK', name: 'Dark Pink', code: '#E75480' },
  { value: 'PINK', name: 'Pink', code: '#FFC0CB' },
  { value: 'BABYPINK', name: 'Baby Pink', code: '#F4C2C2' },
  { value: 'RED', name: 'Red', code: '#FF0000' },
  { value: 'MAROON', name: 'Maroon', code: '#800000' },
  { value: 'BROWN', name: 'Brown', code: '#964B00' },
  { value: 'GREEN', name: 'Green', code: '#00FF00' },
  { value: 'PURPLE', name: 'Purple', code: '#A020F0' },
  { value: 'WINE', name: 'Wine', code: '#722F37' },
  { value: 'GREY', name: 'Grey', code: '#808080' },
  { value: 'AQUABLUE', name: 'Aqua Blue', code: '#00FFFF' },
  { value: 'MLUTICOLOR', name: 'Multi Color', code: '' },
  { value: 'PASTELCOLOR', name: 'Pastel Color', code: '' }
]
