<template>
  <app-header title="Cart" showBackBtn showHomeCta/>
  <div class="mt-4">&nbsp;
  </div>
  <div v-if="isCartEmpty" class="mt-3 text-center">
    <img src="../../assets/static/media/ghost.gif" style="height:200px;">
    <h1>Your cart is empty!</h1>
    <p>Pick up where you left off</p>
    <router-link class="btn-secondary btn" :to="'/home'">View products</router-link>
  </div>
  <div v-else>
  <div class="card product-card mt-3 mb-2 mx-3" v-for="item in savedCartItems" :key="item.id" style="height: 130px;">
    <div class="d-flex justify-content-between">
      <div class="col-10">
        <img alt="..." :src=item.img>
        <h5 class="card-title mb-1" style="font-weight: 400;color:#767676;font-size:12px;">Code: {{ item.code }}</h5>
        <p class="card-text mb-1" v-if="item.color">Color: {{ getColorDisplayName(item.color)}} <span class="badge" :style="'background:'+ getColorCode(item.color)" style="color:#2d2d2d;height: 12px;width: 12px;">&nbsp;</span></p>
        <p class="card-text mb-1">Size: {{ item.size}}</p>
        <p class="card-text mb-2">Qty: {{ item.quantity }}</p>
        <h6 class="card-subtitle">Rs. {{ item.price }}</h6>
      </div>
      <div class="col-2">
          <span class="float-end" style="font-weight: 500;font-size: 14px">
            Rs. {{ item.price * item.quantity }}
          </span>
          <span @click="removeFromCart(item)" class="float-end text-danger mt-5" style="font-weight: 500;font-size: 12px; opacity: 50%;">
            Remove
          </span>
      </div>
    </div>
  </div>
<!--    Add customer-->
    <div v-if="!isCartEmpty && !userDetails">
      <div class="d-flex justify-content-between">
        <p class="my-header text-black-50 mt-3">STEP-1: Select or add new customer <img src="../../assets/static/media/backhand_index_pointing_down.gif" style="height:30px;"></p>
        <router-link class="card-action-link mx-3 mt-4" :to="'/contact'" style="font-weight: 600;">Add New</router-link>
      </div>
      <div class="form-floating mx-3">
        <select style="border-radius: 8px;" v-model="selectedUser" @change="getUserDetails" class="form-select" id="floatingSelect" aria-label="Floating label select example">
          <option selected value="">Select</option>
          <option selected value="add">Add new</option>
          <option v-for="contact in contacts" :key="contact.id" :value="contact.id">
            {{ contact.name }} - {{ contact.mobile }}
          </option>
        </select>
        <label for="floatingSelect">Customer</label>
      </div>
    </div>
    <div v-if="userDetails" class="card desc-card mx-3" style="margin-bottom: 0;">
      <div class="d-flex justify-content-between">
        <h5 class="card-title-uppercase mb-3">Delivery address</h5>
        <a class="card-action-link" href="#" @click.prevent="changeUser()">Change</a>
      </div>
      <h6 class="card-subtitle mb-2 text-muted">{{ userDetails.name }} {{ userDetails.mobile }} </h6>
      <p class="card-text">{{ userDetails.address }}, {{ userDetails.state }}, {{ userDetails.city }}, {{ userDetails.zipcode }}</p>
    </div>
<!--    Payment Option-->
    <div v-if="!isCartEmpty && userDetails" class="mb-5">
      <p class="my-header text-black-50 mt-3">STEP-2: Select payment option <img src="../../assets/static/media/backhand_index_pointing_down.gif" style="height:30px;"></p>
      <div class="card desc-card mt-2 mx-3">
        <div class="form-check mb-2" style="padding-left: 0;">
          <label class="form-check-label d-flex justify-content-between" for="flexRadioDefault2">
          <span class="mt-1" style="font-weight: 600;">Pay cash on delivery
            <span style="font-size:12px;font-weight: 500;color: #06C270;">(NO advance fees)</span>
          </span>
            <input v-model="selectedPaymentOption" value="cod" style="height: 22px;width: 22px;" class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2">
          </label>
        </div>
        <div class="form-check mb-2" style="padding-left: 0;">
          <label class="form-check-label d-flex justify-content-between" for="flexRadioDefault1">
            <span class="mt-1" style="font-weight: 600;">Pay via eSetuWallet
            <span class="text-muted-green mt-1" style="font-size:12px;font-weight: 500;">(Wallet Balance: {{wallet.balanceAccured}})</span></span>
            <input v-model="selectedPaymentOption" value="prepaid" style="height: 22px;width: 22px;" class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
          </label>
        </div>
        <!--      <div class="form-check mb-2" style="padding-left: 0;">-->
        <!--        <label class="form-check-label d-flex justify-content-between" for="flexRadioDefault3">-->
        <!--          &lt;!&ndash; Prepaid - pay via UPI &ndash;&gt;-->
        <!--          <span class="mt-1 text-muted">Pay via <img src="../../assets/static/upi.png"/>-->
        <!--            <span class="text-muted mt-1" style="font-size:12px;font-weight: 500;">(Launching soon)</span></span>-->
        <!--          <input v-model="selectedPaymentOption" value="upi" style="height: 22px;width: 22px;" class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3">-->
        <!--        </label>-->
        <!--      </div>-->
        <div class="form-check mb-2" style="padding-left: 0;">
          <label class="form-check-label d-flex justify-content-between" for="flexRadioDefault4">
            <span class="mt-1" style="font-weight: 600;">I've already paid</span>
            <input v-model="selectedPaymentOption" value="paid" style="height: 22px;width: 22px;" class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault4">
          </label>
        </div>
        <div class="form-floating mt-2" v-show="selectedPaymentOption === 'cod'">
          <input type="number" pattern="[0-9]*" inputmode="numeric" class="form-control" placeholder="price" id="price" v-model="codAmount" required>
          <label for="name">Customer to pay (in Rs.)</label>
        </div>
      </div></div>
<!--    Instructions for customer-->
    <div class="mb-5">&nbsp;</div>
    <div v-if="!isCartEmpty && selectedPaymentOption" class="fixed-bottom">
      <div class="mt-2">
        <div v-show="selectedPaymentOption === 'cod'" class="card-footer card-footer-info" style="text-align: left;background: #FFF5D9;font-weight: 600;font-size: 12px;">
          💰 Your earning will be credited to wallet safely and securely.
        </div>
        <div v-show="selectedPaymentOption == 'prepaid' && isPaymentOptionEnabled" class="card-footer card-footer-info" style="text-align: left;background: #FFF5D9;font-weight: 600;font-size: 12px;">
          🎉 Rs. 40 instant discount applied!
        </div>
        <div v-show="selectedPaymentOption == 'upi' || selectedPaymentOption == 'paid'" class="card-footer card-footer-info" style="text-align: left;background: #FFF5D9;font-weight: 600;font-size: 12px;">
          🎉 Rs. 40 instant discount applied!
        </div>
        <div v-show="selectedPaymentOption == 'prepaid' && !isPaymentOptionEnabled" class="card-footer card-footer-info" style="text-align: left;background: #DB4437;font-weight: 600;font-size: 12px;color: #FFF;">
          ⚠️ Low balance in the wallet!
        </div>
      </div>
<!--      Navbar-->
      <nav class="navbar navbar-light bg-light btn-nav p-2 px-3" style="background: #06C270 !important;">
          <span class="w-48" style="color: #FFF;">
            Rs. {{ totalAmount }} <strike v-if="totalAmount!=savedCartValue">{{savedCartValue}}</strike>
            <br />
            <span class="btn-footer-text" style="color: #FFF;">Total payable</span>
          </span>
        <button :disabled="!canPay" v-show="paybtnstate" v-on:click="createOrder" class="btn btn-primary w-20" style="font-weight: 700;">
          <span v-show="selectedPaymentOption === 'cod' || selectedPaymentOption === 'paid' "><img v-if="canPay" src="../../assets/static/media/backhand_index_pointing_right.gif" style="height:30px;"> ORDER NOW</span>
          <span v-show="selectedPaymentOption !== 'cod' && isPaymentOptionEnabled"><img src="../../assets/static/media/backhand_index_pointing_right.gif" style="height:30px;"> PAY NOW</span>
        </button>
        <button :disabled="!canPay" v-show="paybtnstate && selectedPaymentOption=='upi'" v-on:click="createOrder" class="btn btn-primary w-20" style="font-weight: 700;"><img src="../../assets/static/media/backhand_index_pointing_right.gif" style="height:30px;"> PAY NOW</button>
        <img v-show="!paybtnstate" src="../../assets/static/media/dizzy.gif" style="height:30px;">
      </nav>
    </div>
  </div>
</template>

<script>
import AppHeader from '@/components/AppHeader.vue'
import { mapActions, mapState, mapGetters } from 'vuex'
import { getCart, removeFromCart, resetCart } from '@/storage/cart.module'
import router from '@/router'
import { getLoggedInUser } from '@/storage/auth.module'
import { useRoute } from 'vue-router'
import Mixpanel from 'mixpanel-browser'
import color from '@/data/color'
import api from '@/storage/api'
import { toast } from 'vue3-toastify'

export default {
  name: 'Cart',
  components: {
    AppHeader
  },
  data () {
    return {
      contacts: [],
      selectedUser: '',
      userDetails: null,
      userId: getLoggedInUser().userId,
      userRole: getLoggedInUser().userRole,
      userToken: getLoggedInUser().userToken,
      savedStoreId: getCart().storeId,
      savedCartItems: getCart().cartItems,
      savedCartValue: getCart().cartValue,
      savedCartWeight: getCart().cartWeight,
      savedCartShippingCost: getCart().cartShippingCost,
      paybtnstate: true,
      selectedPaymentOption: null,
      codAmount: null,
      wallet: [],
      prepaidPaymentMode: 'WALLET'
    }
  },
  mounted () {
    const route = useRoute()
    const contactId = route.query.contactId
    if (contactId) {
      this.selectedUser = contactId
      this.getUserDetails()
    }
    this.getUsers()
    this.getWallet()
    Mixpanel.track('Cart Viewed')
  },
  computed: {
    isPaymentOptionEnabled () {
      return this.wallet.balanceAccured >= this.totalAmount
    },
    isCartEmpty () {
      return !this.savedCartItems || this.savedCartItems.length === 0
    },
    totalAmount () {
      if (this.selectedPaymentOption !== 'cod') {
        return Number(this.savedCartValue) - 40
      } else if (this.selectedPaymentOption === 'cod') {
        return Number(this.savedCartValue)
      } else {
        return Number(this.savedCartValue)
      }
    },
    canPay () {
      if (this.selectedPaymentOption !== 'cod') {
        return this.selectedUser && this.selectedPaymentOption
      } else {
        return this.selectedUser && this.selectedPaymentOption && this.codAmount && (this.codAmount >= this.totalAmount)
      }
    }
  },
  methods: {
    ...mapActions(['addToSelectedItems', 'removeFromSelectedItems', 'clearCart']),
    clearStorage () {
      resetCart()
      this.refreshCart()
    },
    refreshCart () {
      this.savedStoreId = getCart().storeId
      this.savedCartItems = getCart().cartItems
      this.savedCartValue = getCart().cartValue
      this.savedCartWeight = getCart().cartWeight
      this.savedCartShippingCost = getCart().cartShippingCost
    },
    removeFromCart (item) {
      const index = this.savedCartItems.findIndex(cartItem => cartItem.id === item.id)
      if (index > -1) {
        removeFromCart(this.savedStoreId, item.id, this.savedCartItems)
        this.refreshCart()
      }
      toast.error('Item removed from the cart!')
      Mixpanel.track('Item removed from cart', {
        'Store Id': this.savedStoreId,
        'Item Id': item.id,
        'Item code': item.code,
        'Color ': item.color,
        'Size ': item.size,
        'Qunatity ': item.quantity,
        'Price ': item.price
      })
    },
    getColorCode (colorName) {
      const selectedColor = color.find(color => color.value === colorName)
      return selectedColor.code
    },
    getColorDisplayName (colorName) {
      const selectedColor = color.find(color => color.value === colorName)
      return selectedColor.name
    },
    async getUsers () {
      api.get(`/contacts?uuid=${this.userToken}`)
        .then(response => response.data)
        .then(data => {
          this.contacts = data
        })
        .catch(error => console.error(error))
    },
    async getWallet () {
      api.get(`/wallet?uuid=${this.userToken}`)
        .then(response => response.data)
        .then(data => {
          this.wallet = data
        })
        .catch(error => console.error(error))
    },
    async getUserDetails () {
      if (this.selectedUser === 'add') {
        this.userDetails = null
        router.push({ name: 'contact' })
      }
      if (this.selectedUser === '') {
        this.userDetails = null
      }
      // fetch(`http://localhost:8080/contacts/${this.selectedUser}?uuid=leh4ejay`)
      api.get(`/contacts/${this.selectedUser}?uuid=${this.userToken}`)
        .then(response => response.data)
        .then(data => {
          this.userDetails = data
          Mixpanel.track('Customer selection', {
            'Contact Id': this.userDetails.id
          })
        })
        .catch(error => {
          console.error(error)
          Mixpanel.track('Customer selection', { status: 'Failure', errorCode: error.code, errorMessage: error.message })
        })
    },
    changeUser () {
      this.userDetails = null
      this.selectedUser = ''
    },
    async createOrder () {
      // this.isClicked = true
      let uri = `/orders?uuid=${this.userToken}`
      if (this.selectedPaymentOption === 'upi') {
        this.selectedPaymentOption = 'PREPAID'
        this.prepaidPaymentMode = 'UPI'
        uri = `/orders/pg?uuid=${this.userToken}`
      }
      if (this.selectedPaymentOption === 'paid') {
        this.selectedPaymentOption = 'PREPAID'
        this.prepaidPaymentMode = 'DIRECT_TRANSFER'
      }
      this.paybtnstate = false
      const orderItem = this.savedCartItems.map(item => {
        return {
          skuId: item.id,
          qty: item.quantity
        }
      })
      const payload = {
        prepaidPaymentMode: this.prepaidPaymentMode,
        paymentMode: this.selectedPaymentOption.toUpperCase(),
        contactId: this.selectedUser,
        codAmount: this.codAmount,
        orderItem
      }
      api.post(uri, payload)
        .then(response => {
          if (response.data.accessKey != null) {
            this.initPayment(response.data.accessKey)
          } else {
            Mixpanel.track('Order', {
              status: 'Success',
              'Order Id': response.data.orderCode,
              'Payment Mode': this.selectedPaymentOption + '-' + this.prepaidPaymentMode,
              'Cart Value': this.totalAmount,
              'COD value': this.codAmount
            })
            resetCart()
            router.push({
              name: 'order-confirmation',
              params: { orderId: response.data.orderCode }
            })
          }
        })
        .catch(error => {
          console.log(error)
          Mixpanel.track('Order', { status: 'Failure', errorCode: error.code, errorMessage: error.message })
        })
    }
  }
}
</script>

<style scoped>

</style>
