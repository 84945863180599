<template>
  <app-header title="Account"/>
  <div id="myDIV" class="modal-open==false">
    <div class="mt-4">&nbsp;</div>
  <div v-if="userRole!=='CUSTOMER'" class="mt-3">
    <p class="my-header text-black-50">Configuration</p>
    <div class="card desc-card card-navigation mx-3 mb-1">
      <div class="d-flex justify-content-between">
        <router-link to="/item/add" class="card-title-uppercase" style="width: 100%;font-weight: 500;text-transform: none;">Add item</router-link>
      </div>
    </div>
  </div>

  <div class="mt-3">
    <p class="my-header text-black-50">Security</p>
    <div class="card desc-card card-navigation mx-3 mb-1">
      <div class="d-flex justify-content-between">
        <a href="#" @click="showPinModalBox()" class="card-title-uppercase" style="width: 100%;font-weight: 500;text-transform: none;">Set login PIN</a>
      </div>
    </div>
  </div>
<!--  <p class="mt-3 my-header text-black-50">Reports</p>-->
<!--  <p class="text-muted mx-3">Launching soon</p>-->
  <a class="nav-link text-center mt-4" href="#" v-on:click="logout()" style="color:#DB4437;border: none;opacity: 50%;font-weight: 100;"><i class="bi bi-box-arrow-right" style="color:#DB4437;"></i> logout</a>
  <nav-bottom></nav-bottom>
  </div>

  <div v-show="showPinModal">
    <div class="modal" tabindex="-1" role="dialog" ref="modal">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Setup login PIN</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="hidePinModalBox"></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="setupPin()">
              <div class="form-floating mb-1">
                <input type="password" pattern="[0-9]*" inputmode="numeric" class="form-control" placeholder="Pin" id="pin" v-model.number="pinOne" required>
                <label for="pin">Enter 4 digit PIN</label>
              </div>
              <div class="form-floating mb-1">
                <input type="password" pattern="[0-9]*" inputmode="numeric" class="form-control" placeholder="Pin" id="pin" v-model.number="pinTwo" required>
                <label for="pin">Re-Enter 4 digit PIN</label>
                <small v-if="!isPinMatches && isPinValid" id="passwordHelpBlock" class="text-danger">
                  Pin is not matching!
                </small>
              </div>
              <div class="modal-footer">
                <button :disabled="!isFormValid" type="submit" class="btn btn-primary col-12">Secure now</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- success toast message -->
  <div class="toast-container position-fixed top-0 text-center mb-3 mx-3" >
    <div class="toast align-items-center text-white bg-success border-0" role="alert"
         :class="{ 'show': isShowSuccessToast }">
      <div class="d-flex">
        <div class="toast-body">
          Pin changed successfully!
        </div>
        <button type="button" class="btn-close me-2 m-auto" data-bs-dismiss="toast" aria-label="Close" @click.prevent="isShowSuccessToast = false"></button>
      </div>
    </div>
  </div>
</template>

<script>
import AppHeader from '@/components/AppHeader.vue'
import { getLoggedInUser, logoutUser, setUser } from '@/storage/auth.module'
import api from '@/storage/api'
import Mixpanel from 'mixpanel-browser'
import router from '@/router'
import NavBottom from '@/components/NavBottom.vue'
import AppFooter from '@/components/AppFooter.vue'

export default {
  name: 'Account',
  components: {
    NavBottom,
    AppHeader
  },
  data () {
    return {
      userId: getLoggedInUser().userId,
      userRole: getLoggedInUser().userRole,
      userToken: getLoggedInUser().userToken,
      showPinModal: false,
      isShowSuccessToast: false,
      pinOne: '',
      pinTwo: ''
    }
  },
  setup () {
    function logout () {
      logoutUser()
    }
    return { logout }
  },
  computed: {
    isPinValid () {
      return /^[0-9]{4}$/.test(this.pinOne)
    },
    isPinMatches () {
      return this.pinOne === this.pinTwo
    },
    isFormValid () {
      return this.isPinValid && this.isPinMatches
    }
  },
  methods: {
    showPinModalBox () {
      this.showPinModal = true
      this.$refs.modal.classList.add('show')
      this.$refs.modal.style.display = 'block'
      const myDiv = document.getElementById('myDIV')
      myDiv.classList.add('modal-open')
    },
    hidePinModalBox () {
      this.showPinModal = false
      this.$refs.modal.classList.remove('show')
      this.$refs.modal.style.display = 'none'
      const myDiv = document.getElementById('myDIV')
      myDiv.classList.remove('modal-open')
    },
    setupPin () {
      api.put('/users/pin/change?uuid=' + this.userToken + '&pin=' + this.pinOne)
        .then(response => {
          if (response.data) {
            this.loggedinUser = response.data
            Mixpanel.track('Pin change', { status: 'Success' })
            this.pinOne = ''
            this.pinTwo = ''
            this.hidePinModalBox()
            this.isShowSuccessToast = true
          }
        })
        .catch(error => {
          console.log(error)
          this.isShowSuccessToast = false
          Mixpanel.track('Pin change', { status: 'Failure', errorCode: error.code, errorMessage: error.message })
        })
    }
  }
}
</script>

<style scoped>
.nav-icon .nav-pills .nav-link.active, .nav-pills .show > .nav-link{
  background: #F6F6F6;
}
.modal-dialog {
  position: fixed;
  bottom: 0;
  left: 0%;
  right: 0%;
  transform: translate(-50%, -50%);
}
.modal-open {
  filter: grayscale(100%) opacity(30%) blur(4px);
}
</style>
