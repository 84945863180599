<template>
  <div class="container">
<h3>Test push notification</h3>
  <div> {{this.gtoken}} </div>
  </div>
</template>

<script>
import { initializeApp } from 'firebase/app'
import firebaseConfig from '@/firebaseConfig'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'

export default {
  name: 'Push',
  data () {
    return {
      gtoken: ''
    }
  },
  mounted () {
    const app = initializeApp(firebaseConfig)
    const messaging = getMessaging()
    onMessage(messaging, (payload) => {
      console.log('Message received. ', payload)
    })
    // getToken(messaging, { vapidKey: 'BAXpESTRK-2Wva46A15-tBZmhEJtyIn8aM31Dctx_6Csqn89Gb0Meg54xQUBz3Om2skL-27kwgBAa4R6IzeyXGI' }).then((currentToken) => {
    //   if (currentToken) {
    //     this.gtoken = currentToken
    //     console.log('Token is:', currentToken)
    //   } else {
    //     console.log('No registration token available. Request permission to generate one.')
    //   }
    // }).catch((err) => {
    //   console.log('An error occurred while retrieving token. ', err)
    // })
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        console.log('Notification permission granted.')
        getToken(messaging, { vapidKey: 'BAXpESTRK-2Wva46A15-tBZmhEJtyIn8aM31Dctx_6Csqn89Gb0Meg54xQUBz3Om2skL-27kwgBAa4R6IzeyXGI' }).then((currentToken) => {
          if (currentToken) {
            console.log('Got FCM token:', currentToken)
          } else {
            console.log('No FCM token available.')
          }
        }).catch((error) => {
          console.log('Error getting FCM token:', error)
        })
      } else {
        console.log('Notification permission denied.')
      }
    })
  }
}
</script>

<style scoped>

</style>
