import { createStore } from 'vuex'
import UserModule from './user.module'
import StoresModule from './stores.module'
// import AuthModule from './auth.module'
import CartsModule from './carts.module'
export default createStore({
  modules: {
    // auth: AuthModule,
    user: UserModule,
    stores: StoresModule,
    carts: CartsModule
  }
})
