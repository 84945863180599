export default {
  namespaced: true,
  state: {
    firstName: 'Kartik',
    lastName: 'Patel'
  },
  getters: {
    fullName: function (state: any) {
      return `${state.firstName} ${state.lastName}`
    }
  }
}
