<template>
  <splash-screen v-if="loading" />
  <div id="myDIV" class="modal-open==false">
  <div class="text-center mt-4 mb-3">
    <router-link to="/"><img alt="eSetu" class="rounded-circle rotate-image" src="../assets/icon-72x72.svg"></router-link>
  </div>
  <div class="px-3 mb-4">
    <h1>Hey, guest! <img src="../assets/static/media/waving_hand.gif" class="align-bottom" style="height:40px;"></h1>
    <p class="text-black-50">Discover our offerings in just 3 simple steps!</p>
  </div>
  <div class="list-group mt-4">
    <a href="#" class="list-group-item list-group-item-action flex-column">
      <div class="d-flex w-100 justify-content-between">
        <h5 class="mb-1" style="font-weight: 700;">1. Click "Continue with WhatsApp"</h5>
      </div>
      <p class="mb-1">Send WhatsApp message on your phone to login</p>
    </a>
    <a href="#" class="list-group-item list-group-item-action flex-column align-items-start">
      <div class="d-flex w-100 justify-content-between">
        <h5 class="mb-1" style="font-weight: 700;">2. Send a message to verify</h5>
      </div>
      <p class="mb-1">By sending the message, you will get a login link. Click on it!</p>
    </a>
    <a href="#" class="list-group-item list-group-item-action flex-column align-items-start">
      <div class="d-flex w-100 justify-content-between">
        <h5 class="mb-1" style="font-weight: 700;">3. Login</h5>
      </div>
      <p class="mb-1">Successfully signed-in with WhatsApp</p>
    </a>
  </div>
  <nav class="mt-0 pt-3">
    <div class="text-center">
<!--      {{newUser}}-->
<!--      <router-link to="/home">-->
<!--      <button id="whatsapp-login"/>-->
<!--      <button id="whatsapp-login">Login with WhatsApp</button>-->
<!--      <a href="https://esetu.authlink.me/signup?appId=cpe3utzg">-->
<!--      <button type="button" onclick="window.open('https://esetu.authlink.me', '_self')" style="border: none; background: transparent; outline: none;">-->
<!--        <img src=" https://d2zrdrk5t0omov.cloudfront.net/otpless_button.svg" style="width:300px" />-->
<!--      </button>-->
      <p v-if="loading" class="text-black-50">Verifying...
        <img src="../assets/static/media/dizzy.gif" class="align-bottom" style="height:20px;">
      </p>
      <div v-else>
      <a href="https://esetu.authlink.me" target="_self" @click="letMeIn">
      <img src="../assets/static/media/whatsapp-button.2fd29fff.svg" style="width:300px">
      </a>
      <p class="text-muted mb-1">By accessing our platform, you agree to our <a href="/terms">terms</a>.</p>
      </div>
      <div class="container">
      <div class="row">
        <div class="col">
          <hr>
        </div>
        <div class="col-auto mt-2 text-muted">&nbsp;OR&nbsp;</div>
        <div class="col">
          <hr>
        </div>
      </div>
      </div>
      <a class="card-title-uppercase text-primary" href="#" @click="showLoginModalBox()"><i class="fa-solid fa-lock"></i>  Login with PIN</a>
      <p class="text-muted mb-1">If you're a new user, please signup using <a href="https://esetu.authlink.me" target="_self" @click="letMeIn">Whatsapp</a>.</p>
    </div>
  </nav>
  <app-footer class="mt-0"/>
  </div>
  <div v-show="showLoginModal">
    <div class="modal" tabindex="-1" role="dialog" ref="modal">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Secure login with PIN</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="hideLoginModalBox"></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="loginWithPin()">
              <div class="input-group" v-if="changeWaNumber">
              <div class="input-group-prepend">
                <div class="input-group-text" style="padding: 16.5px;">+91</div>
              </div>
                <div class="form-floating mb-3 mr-sm-2">
                  <input type="number" pattern="[0-9]*" inputmode="numeric" class="form-control" placeholder="Mobile" id="phone" v-model="waNumber" required>
                  <label for="mobile">Mobile number</label>
                </div>
              </div>
              <div class="mb-3" v-else>
                <label for="mobile" class="small">Mobile number ( <a href="#" @click="changeMobile()"> <i class="fa fa-pencil"></i> change</a> )</label>
                <h6 class="fw-bold" style="">{{waNumber}}</h6>
              </div>
              <div class="form-floating mb-1">
                <input type="password" pattern="[0-9]*" inputmode="numeric" class="form-control" placeholder="Pin" id="pin" v-model.number="pin" required>
                <label for="pin">Enter 4 digit PIN</label>
                <small id="passwordHelpBlock" class="form-text text-muted">
                  Please don't share your pin with anyone!
                </small>
              </div>
              <div class="modal-footer">
                <button :disabled="!isFormValid" type="submit" class="btn btn-primary col-12"> Login securely</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- error toast message -->
  <div class="toast-container position-fixed top-0 text-center mb-3 mx-4" >
    <div class="toast align-items-center text-white bg-danger border-0" role="alert"
         :class="{ 'show': isShowErrorToast }">
      <div class="d-flex">
        <div class="toast-body">
          {{ errorMessage }}
        </div>
        <button type="button" class="btn-close me-2 m-auto" data-bs-dismiss="toast" aria-label="Close" @click.prevent="isShowErrorToast = false"></button>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted } from 'vue'
import router from '@/router'
import { getLoggedInUser, setUser, getLoggedInUserMobile, setUserMobile } from '@/storage/auth.module'
import { useRoute } from 'vue-router'
import axios from 'axios'
import api from '@/storage/api'
import Mixpanel from 'mixpanel-browser'
import SplashScreen from '@/components/SplashScreen.vue'
import AppFooter from '@/components/AppFooter.vue'

Mixpanel.init(process.env.VUE_APP_MIXPANEL_TOKEN)

export default {
  components: {
    SplashScreen,
    AppFooter
  },
  created () {
    const metaTags = [
      { name: 'description', content: "eSetu's AI connects you to the top suppliers so that you can Sell More. Join eSetu today and take advantage of our trustworthy platform to grow your business and reach new heights." },
      { property: 'og:title', content: 'eSetu - Connecting You to Top Suppliers in India' },
      { property: 'og:description', content: "eSetu's AI connects you to the top suppliers so that you can Sell More. Join eSetu today and take advantage of our trustworthy platform to grow your business and reach new heights." },
      { property: 'og:url', content: 'https://esetu.in/login?utm_source=meta' }
    ]
    const setMetaTags = (tags) => {
      tags.forEach(tag => {
        const metaTag = document.createElement('meta')
        Object.keys(tag).forEach(key => {
          metaTag.setAttribute(key, tag[key])
        })
        document.head.appendChild(metaTag)
      })
    }
    setMetaTags(metaTags)
  },
  computed: {
    isPhoneValid () {
      return /^[0-9]{10}$/.test(this.waNumber)
    },
    isPinValid () {
      return /^[0-9]{4}$/.test(this.pin)
    },
    isFormValid () {
      return this.isPhoneValid && this.isPinValid
    }
  },
  mounted () {
    if (!this.waNumber) {
      this.changeWaNumber = true
    }
    Mixpanel.track('Login Page Viewed')
    const route = useRoute()
    const utmSource = route.query.utm_source
    const utmMedium = route.query.utm_medium
    if (route.query.utm_source) {
      Mixpanel.register({
        utm_source: utmSource
      })
    }
    if (route.query.utm_medium) {
      Mixpanel.register({
        utm_medium: utmMedium
      })
    }
    const script = document.createElement('script')
    script.src = 'https://esetu.authlink.me/js/sdk/otpless.js'
    document.head.appendChild(script)
    window.otpless = (otplessUser) => {
      console.log(otplessUser)
    }
    if (route.query.waId) {
      this.loading = true
      this.getUserDetails(route.query.waId)
    }
  },
  // setup () {
  //   const newUser = {
  //     userId: 'U001',
  //     userRole: 'R002',
  //     userToken: 'T003'
  //   }
  //   onMounted(() => {
  //     if (getLoggedInUser().userId) {
  //       router.push({ name: 'home' })
  //     }
  //   })
  //   function authUser (newUser) {
  //     setUser(newUser.userId, newUser.userRole, newUser.userToken)
  //   }
  //   return { authUser, newUser }
  // },
  data () {
    return {
      token: '',
      loading: false,
      user: [],
      loggedinUser: {
        id: 'any',
        role: 'any',
        uuid: 'any'
      },
      userId: getLoggedInUser().userId,
      userRole: getLoggedInUser().userRole,
      userToken: getLoggedInUser().userToken,
      stores: [],
      store: [],
      query: '',
      showLoginModal: false,
      waNumber: getLoggedInUserMobile().mobile,
      pin: '',
      isShowErrorToast: false,
      errorMessage: '',
      changeWaNumber: false
    }
  },
  methods: {
    changeMobile () {
      if (!this.waNumber) {
        this.changeWaNumber = false
      } else {
        this.changeWaNumber = true
      }
    },
    showLoginModalBox () {
      this.showLoginModal = true
      this.$refs.modal.classList.add('show')
      this.$refs.modal.style.display = 'block'
      const myDiv = document.getElementById('myDIV')
      myDiv.classList.add('modal-open')
    },
    hideLoginModalBox () {
      this.showLoginModal = false
      this.$refs.modal.classList.remove('show')
      this.$refs.modal.style.display = 'none'
      const myDiv = document.getElementById('myDIV')
      myDiv.classList.remove('modal-open')
      this.changeWaNumber = false
    },
    loginWithPin () {
      api.get('/users/pin/verify?waNumber=' + this.waNumber + '&pin=' + this.pin)
        .then(response => {
          if (response.data) {
            this.loggedinUser = response.data
            setUser(this.loggedinUser.id, this.loggedinUser.role, this.loggedinUser.uuid)
            this.userId = getLoggedInUser().userId
            this.userRole = getLoggedInUser().userRole
            this.userToken = getLoggedInUser().userToken
            setUserMobile(this.waNumber)
            Mixpanel.identify(this.loggedinUser.id)
            Mixpanel.people.set({
              $distinct_id: this.loggedinUser.id,
              $role: this.loggedinUser.role,
              $name: this.loggedinUser.waName
            })
            Mixpanel.track('Login', { status: 'Success', method: 'PIN' })
            router.push({
              name: 'homePage'
            })
          } else {
            this.isShowErrorToast = true
            this.errorMessage = 'Invalid PIN'
            Mixpanel.track('Login', { status: 'Failure', method: 'PIN', errorMessage: this.errorMessage })
          }
        })
        .catch(error => {
          console.log(error)
          this.isShowErrorToast = true
          this.errorMessage = 'Mobile number doesn\'t exists'
          Mixpanel.track('Login', { status: 'Failure', method: 'PIN', errorMessage: this.errorMessage })
        })
    },
    letMeIn () {
      this.loading = true
    },
    async getUserDetails (waId) {
      api.get('/users/otp/verify?waId=' + waId)
        .then(response => response.data)
        .then(data => {
          // console.log(data.uuid)
          // router.push({
          //   name: 'home',
          //   params: { token: data.uuid }
          // })
          this.loading = true
          this.loggedinUser = data
          setUser(this.loggedinUser.id, this.loggedinUser.role, this.loggedinUser.uuid)
          this.userId = getLoggedInUser().userId
          this.userRole = getLoggedInUser().userRole
          this.userToken = getLoggedInUser().userToken

          Mixpanel.identify(this.loggedinUser.id)
          Mixpanel.people.set({
            $distinct_id: this.loggedinUser.id,
            $role: this.loggedinUser.role,
            $name: this.loggedinUser.waName
          })
          // Mixpanel.people.set(this.loggedinUser.id, {
          //   $role: this.loggedinUser.role,
          //   $name: this.loggedinUser.waName
          // })
          Mixpanel.track('Login', { status: 'Success', method: 'WA' })
          router.push({
            name: 'homePage'
          })
        })
        .catch(error => {
          this.loading = false
          Mixpanel.track('Login', { status: 'Failure', method: 'WA', errorCode: error.code, errorMessage: error.message })
          console.error(error)
        })
    }
  }
}
</script>

<style scoped lang="scss">
.list-group a{
  background: transparent;
  border: none;
}
.list-group h5.mb-1{
  font-weight: 500;
  color: #2D2D2D;
}
.list-group p{
  color: #B4B4B4;
}
.list-group a{
  padding-bottom: 20px;
}
.modal-dialog {
  position: fixed;
  bottom: 0;
  left: 0%;
  right: 0%;
  transform: translate(-50%, -50%);
}
.modal-open {
  filter: grayscale(100%) opacity(30%) blur(4px);
}
hr {
  opacity: 0.10;
  border: 0.5px solid;
}
</style>
