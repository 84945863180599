import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Item from '../views/store/Items.vue'
import Cart from '../views/cart/Cart.vue'
import Landing from '../views/other/Landing.vue'
import Contact from '../views/cart/Contact.vue'
import Tutorial from '../views/tutorial/Tutorial.vue'
import PageNotFound from '@/views/other/PageNotFound.vue'
// import StoreItem from '@/views/tutorial/StoreItem.vue'
import { getLoggedInUser } from '@/storage/auth.module'
import OrderConfirmation from '@/views/cart/OrderConfirmation.vue'
import Order from '@/views/order/Orders.vue'
import OrderDetail from '@/views/order/OrderDetail.vue'
import ItemAdd from '@/views/item/ItemAdd.vue'
import Account from '@/views/other/Account.vue'
import Wallet from '@/views/wallet/Wallet.vue'
import OrderPrint from '@/views/order/OrderPrint.vue'
import Terms from '@/views/other/Terms.vue'
import Search from '@/views/Search.vue'
import WalletHistory from '@/views/wallet/WalletHistory.vue'
import WalletRedeemRequest from '@/views/wallet/WalletRedeemRequest.vue'
import ItemDetail from '@/views/item/ItemDetail.vue'
import Push from '@/views/other/Push.vue'
import CartNew from '@/views/cart/CartNew.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'landing',
    component: Landing
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    beforeEnter: (to, from, next) => {
      if (getLoggedInUser().userToken) {
        next('/home')
      } else {
        next()
      }
    }
  }, {
    path: '/home',
    name: 'homePage',
    component: Home,
    props: true,
    beforeEnter: (to, from, next) => {
      if (getLoggedInUser().userId) {
        next()
      } else {
        next('/login')
      }
    }
  },
  {
    path: '/home/:token',
    name: 'home',
    component: Home,
    props: true // enables passing props through route
  },
  {
    path: '/search',
    name: 'search',
    component: Search,
    beforeEnter: (to, from, next) => {
      if (getLoggedInUser().userToken) {
        next()
      } else {
        next('/login')
      }
    }
  },
  {
    path: '/item/add',
    name: 'itemadd',
    component: ItemAdd,
    beforeEnter: (to, from, next) => {
      if (getLoggedInUser().userRole !== 'CUSTOMER') {
        next()
      } else {
        next('/login')
      }
    }
  },
  {
    path: '/store/:storeName',
    name: 'item',
    component: Item
  },
  {
    path: '/store/:storeName/:itemId',
    name: 'itemDetail',
    component: ItemDetail
  },
  {
    path: '/cart',
    name: 'cart',
    component: Cart,
    props: true,
    beforeEnter: (to, from, next) => {
      if (getLoggedInUser().userToken) {
        next()
      } else {
        next('/login')
      }
    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact,
    beforeEnter: (to, from, next) => {
      if (getLoggedInUser().userToken) {
        next()
      } else {
        next('/login')
      }
    }
  },
  {
    path: '/order-confirmation/:orderId',
    name: 'order-confirmation',
    component: OrderConfirmation,
    props: true // enables passing props through route
  },
  {
    path: '/order',
    name: 'order',
    component: Order,
    beforeEnter: (to, from, next) => {
      if (getLoggedInUser().userToken) {
        next()
      } else {
        next('/login')
      }
    }
  },
  {
    path: '/order/print',
    name: 'orderPrint',
    component: OrderPrint,
    beforeEnter: (to, from, next) => {
      if (getLoggedInUser().userRole !== 'CUSTOMER') {
        next()
      } else {
        next('/login')
      }
    }
  },
  {
    path: '/order/:orderId',
    name: 'orderDetail',
    component: OrderDetail,
    // children: [{ path: 'item', name: 'StoreItem', component: StoreItem }],
    beforeEnter: (to, from, next) => {
      if (getLoggedInUser().userToken) {
        next()
      } else {
        next('/login')
      }
    }
  },
  {
    path: '/wallet',
    name: 'wallet',
    component: Wallet,
    beforeEnter: (to, from, next) => {
      if (getLoggedInUser().userToken) {
        next()
      } else {
        next('/login')
      }
    }
  },
  {
    path: '/wallet/history',
    name: 'WalletHistory',
    component: WalletHistory,
    beforeEnter: (to, from, next) => {
      if (getLoggedInUser().userToken) {
        next()
      } else {
        next('/login')
      }
    }
  },
  {
    path: '/wallet/request',
    name: 'WalletRedeemRequest',
    component: WalletRedeemRequest,
    beforeEnter: (to, from, next) => {
      if (getLoggedInUser().userToken) {
        next()
      } else {
        next('/login')
      }
    }
  },
  {
    path: '/account',
    name: 'account',
    component: Account,
    beforeEnter: (to, from, next) => {
      if (getLoggedInUser().userToken) {
        next()
      } else {
        next('/login')
      }
    }
  },
  {
    path: '/:catchAll(.*)*',
    name: 'PageNotFound',
    component: PageNotFound
  },
  {
    path: '/terms',
    name: 'terms',
    component: Terms
  },
  {
    path: '/push',
    name: 'push',
    component: Push
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

// {
//   path: '/store',
//     name: 'store',
//   component: Store
// },
// {
//   path: '/store/:code',
//     name: 'StoreDetail',
//   component: StoreDetail,
//   children: [{ path: 'item', name: 'StoreItem', component: StoreItem }]
// },
// {
//   path: '/about',
//   name: 'about',
//   // route level code-splitting
//   // this generates a separate chunk (about.[hash].js) for this route
//   // which is lazy-loaded when the route is visited.
//   component: () => import(/* webpackChunkName: "about" */ '../views/Store.vue')
// },
// {
//   path: '/tutorial',
//     name: 'tutorial',
//   component: Tutorial
// }
