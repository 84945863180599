export default {
  state: {
    storeId: '',
    cartItems: [],
    cartValue: 0,
    cartWeight: 0,
    cartShippingCost: 0,
    cartId: ''
  },
  mutations: {
    addItem (state: any, item: any) {
      state.cartItems.push(item)
      state.cartValue += (item.price * item.quantity)
      state.cartWeight += (item.weight * item.quantity)
      state.cartShippingCost += (item.shippingCost * item.quantity)
    },
    removeItem (state: any, item: any) {
      // state.cartValue -= state.cartItems[index].price * state.cartItems[index].quantity
      state.cartValue -= (item.price * item.quantity)
      state.cartWeight -= (item.weight * item.quantity)
      state.cartShippingCost -= (item.shippingCost * item.quantity)
      state.cartItems.splice(item, 1)
    },
    resetState (state: any) {
      state.storeId = ''
      state.cartItems = []
      state.cartValue = 0
      state.cartWeight = 0
      state.cartShippingCost = 0
    }
  },
  actions: {
    addToSelectedItems ({ commit }:any, item: any) {
      commit('addItem', item)
    },
    removeFromSelectedItems ({ commit }:any, index: any) {
      commit('removeItem', index)
    },
    clearCart ({ commit }: any) {
      commit('resetState')
    }
  },
  getters: {
    cartItemsWithQuantities (state: any) {
      return state.cartItems.map((item: any) => {
        const quantity = state.cartItems.filter((i: any) => i.id === item.id).length
        return {
          ...item,
          quantity
        }
      })
    }
  }
}
