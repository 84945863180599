export default [
  {
    id: 1,
    abbreviation: 'AIRWAYS',
    link: 'https://airwayscourier.co.in/Tracking_new.aspx?type=w&awb_no='
  },
  {
    id: 2,
    abbreviation: 'ANJANI',
    link: 'https://www.aftership.com/track/shreeanjanicourier/'
  },
  {
    id: 3,
    abbreviation: 'DELHIVERY',
    link: 'https://www.aftership.com/track/delhivery/'
  },
  {
    id: 4,
    abbreviation: 'DTDC',
    link: 'https://www.aftership.com/track/dtdc/'
  },
  {
    id: 5,
    abbreviation: 'FRANCH',
    link: 'https://erpfranchexpress.com/awb_tracking2.php?keyword='
  },
  {
    id: 6,
    abbreviation: 'TIRUPATI',
    link: 'https://www.aftership.com/track/shreetirupati/'
  },
  {
    id: 7,
    abbreviation: 'TRACKON',
    link: 'https://www.aftership.com/track/trackon/'
  },
  {
    id: 8,
    abbreviation: 'BLUEDART',
    link: 'https://www.aftership.com/track/bluedart/'
  },
  {
    id: 9,
    abbreviation: 'XPRESSBEES',
    link: 'https://www.aftership.com/track/xpressbees/'
  }
]
