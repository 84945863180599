<template>
  <splash-screen v-if="loading" />
  <app-header :wa-number="store.waNumber" :mobile="store.mobile" :title="store.displayName" :wa-msg="'My query is: '" showBackBtn showContactCta/>
  <div class="mt-4">&nbsp;</div>
<!--    <div class="d-flex justify-content-between px-2 mt-3">-->
<!--      <div class="dropdown mb-2">-->
<!--        <button class="mx-2 btn btn-secondary  btn-sm dropdown-toggle" style="padding:5px 10px;" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">-->
<!--          Items: {{selectedOption}}-->
<!--        </button>-->
<!--        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">-->
<!--          <li v-if="userRole!=='CUSTOMER'"><a class="dropdown-item selected" href="#" @click="selectOption('ALL')">All</a></li>-->
<!--          <li><a class="dropdown-item" href="#" @click="selectOption('ACTIVE')">Active</a></li>-->
<!--          <li v-if="userRole!=='CUSTOMER'"><a class="dropdown-item" href="#" @click="selectOption('INACTIVE')">Inactive</a></li>-->
<!--        </ul>-->
<!--      </div>-->
<!--      <a class="card-action-link mx-2 my-2" href="#" @click.prevent="showSearch = !showSearch"><i class="fa-solid fa-magnifying-glass"></i> Search</a>-->
<!--    </div>-->
<!--    <div v-if="showSearch" class="input-group search-box mb-2">-->
<!--      <input type="text" class="form-control  mx-3" placeholder="Search product code" v-model="query">-->
<!--      <button type="button" class="btn bg-transparent" style="margin-right:10px;border: none;" @click="clearSearch(false)">-->
<!--        <i class="fa fa-times"></i>-->
<!--      </button>-->
<!--    </div>-->
<!--    <div v-if="filteredItems.length==0 && selectedOption == 'ACTIVE'" class="container text-center mt-5">-->
<!--      <img src="../../assets/static/media/ghost.gif" style="height:200px;">-->
<!--      <h4>Currently, there is no product in <br/><b style="font-weight: 700;">{{store.displayName}}</b>!</h4>-->
<!--      <p>You can order new products from other stores.</p>-->
<!--      <router-link class="btn-secondary-orange btn mb-5" to="/home">Order Now</router-link>-->
<!--    </div>-->
  <div class="container mt-1">
    <div class="card avatar-card" style="box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;">
      <span class="avatar avatar-xs rounded-circle">
       <span v-if="store.profileImg==null">{{store.initials}}</span>
      <img v-else :src=store.profileImg />
      </span>
      <h5 class="card-title mb-2 mt-0">{{ store.handler }}
        <i class="bi bi-patch-check-fill verified"></i>
      </h5>
      <h6 class="card-subtitle mb-2">joined {{timeSinceLastItemUpdated(store.createdDate)}}</h6>
      <h6 class="card-subtitle text-muted">last update {{timeSinceLastItemUpdated(store.lastItemAddedDate)}}</h6>
    </div>
  </div>
  <div class="container mt-3">
    <div class="card-group row">
      <div class="card col-6 catalog-card mb-0 mt-0 py-0" v-for="item in items" :key="item.id" style="box-shadow: none;border-radius: 0;">
        <router-link :to="'/store/' + store.handler+ '/'+ item.setuCode" @click="itemClickEvent(item.setuCode, item.code)"><div class="card-body">
          <div class="text-center">
                        <span class="MuiTypography-root MuiTypography-small css-ahj6ry" v-if="!item.active">Unavailable</span>
            <div class="css-50wgu">
              <img alt="..." :src="item.img.replace('w_200,h_200', 'w_400,h_400')" :style="item.active ? '' : 'filter: grayscale(100%);'"  style="width: 100%;height: 100%;border-radius: 8px;"></div>
          </div>
          <p class="product-title mt-2 mb-1 text-muted">{{item.title}}</p>
          <p class="product-title mb-1">{{item.code}} (color {{ item.sku.length }})
          <span class="badge new-purple" v-if="isLatest(item.createdDate)" >New arrival</span></p>
          <p class="product-cp mt-1" style="font-size: 16px;font-weight: 600;">Rs. {{item.displayPrice}}</p>
          <h6 class="card-subtitle mt-2 text-muted">added {{timeSinceLastItemUpdated(item.lastModifiedDate)}}</h6>
        </div></router-link>
      </div>
    </div>
  </div>
  <infinite-loading ref="infiniteLoading" @infinite="getItemFeed" spinner="bubbles" direction="bottom" :distance="10">
  </infinite-loading>
  <app-footer class="mt-0"/>
</template>

<script>
import AppHeader from '@/components/AppHeader.vue'
import { getLoggedInUser } from '@/storage/auth.module'
import { useRoute } from 'vue-router'
import api from '@/storage/api'
import Mixpanel from 'mixpanel-browser'
import { timeSinceLastItemUpdated } from '@/utility/utils'
import SplashScreen from '@/components/SplashScreen.vue'
import AppFooter from '@/components/AppFooter.vue'

export default {
  name: 'items',
  emits: ['$InfiniteLoading:loaded'],
  components: {
    AppFooter,
    AppHeader,
    SplashScreen
  },
  data () {
    return {
      query: '',
      items: [],
      userId: getLoggedInUser().userId,
      userRole: getLoggedInUser().userRole,
      userToken: getLoggedInUser().userToken,
      selectedOption: 'ALL',
      showSearch: false,
      storeName: '',
      store: [],
      page: 0,
      perPage: 10,
      loading: true
    }
  },
  mounted () {
    this.fetchStore()
    const route = useRoute()
    this.storeName = route.params.storeName
  },
  computed: {
    filteredItems () {
      let filtered = this.items
      if (this.query) {
        return this.items.filter(item => item.code.toLowerCase().includes(this.query.toLowerCase()))
      }
      if (this.selectedOption === 'ALL') {
        return this.items
      }
      if (this.selectedOption === 'ACTIVE') {
        filtered = filtered.filter(item => item.active === true)
        return filtered
      }
      if (this.selectedOption === 'INACTIVE') {
        filtered = filtered.filter(item => item.active === false)
        return filtered
      }
      return null
    },
    today () {
      return new Date().toISOString().substr(0, 10)
    }
  },
  methods: {
    timeSinceLastItemUpdated,
    isLatest (createdDate) {
      return createdDate.substr(0, 10) === this.today
    },
    formattedTitle (title) {
      const lowercaseText = title.toLowerCase()
      if (lowercaseText.length >= 30) {
        return lowercaseText.substring(0, 30) + '...'
      } else {
        return lowercaseText
      }
    },
    getItemFeed () {
      api.get(`/items/${this.page}/${this.perPage}?handler=${this.storeName}`)
        .then(response => response.data)
        .then(data => {
          const newitems = data.content
          this.items = [...this.items, ...newitems]
          this.page++
          this.$emit('$InfiniteLoading:loaded')
          this.loading = false
        })
        .catch(error => {
          console.error(error)
          this.$refs.infiniteLoading.$emit('$InfiniteLoading:error')
        })
    },
    clearSearch (status) {
      this.showSearch = status
      this.query = ''
    },
    selectOption (option) {
      this.selectedOption = option
    },
    fetchStore () {
      const route = useRoute()
      api.get(`/store?handler=${route.params.storeName}`)
        .then(response => response.data)
        .then(data => {
          this.store = data
        })
        .catch(error => console.error(error))
    },
    itemClickEvent (itemSetuCode, itemCode) {
      Mixpanel.track('Product clicked', {
        'Item Setu Code': itemSetuCode,
        'Item Code': itemCode
      })
    }
  }
}
</script>

<style scoped>
.modal-customisation {
  position: fixed;
  bottom: 0;
  left: 0%;
  right: 0%;
  transform: translate(-50%, -50%);
  z-index: 999;
}
.modal-open {
  /*filter: blur(4px);*/
  filter: grayscale(100%) opacity(30%) blur(4px);
}
.grayscale {
  filter: grayscale(100%);
}
</style>
