<template>
  <div class="mt-3 text-center" v-if="loading">
    <p class="text-black-50">Please wait...Getting payment status!
      <img src="../../assets/static/media/dizzy.gif" class="align-bottom" style="height:20px;">
    </p>
  </div>
  <div v-else>
    <div class="container avatar-card payment success-bg p-3 mb-3" style="border-radius: 0;">
      <img src="../../assets/static/media/partying-face.gif" style="height:50px;" class="my-3">
      <h6>Order placed!</h6>
      <h6 style="font-weight: 600;">Thank you</h6>
    </div>
<!--  <div v-show="paymentStatus == 'PAID'" class="container avatar-card payment success-bg p-3 mb-3" style="border-radius: 0;">-->
<!--    <img src="../../assets/static/media/partying-face.gif" style="height:50px;" class="my-3">-->
<!--    <h6>Order placed!</h6>-->
<!--    <h6 style="font-weight: 600;">Thank you</h6>-->
<!--  </div>-->
<!--  <div v-show="paymentStatus == 'INITIATED'" class="container avatar-card payment pending-bg p-3 mb-3" style="border-radius: 0;">-->
<!--    <img src="../../assets/static/media/exploding_head.gif" style="height:50px;" class="my-3">-->
<!--    <h6>Completing your payment</h6>-->
<!--    <h6 style="font-weight: 600;">Please do not go back or close the app just yet</h6>-->
<!--  </div>-->
<!--  <div v-show="paymentStatus == 'PENDING'" class="container avatar-card payment failed-bg p-3 mb-3" style="border-radius: 0;">-->
<!--    <img src="../../assets/static/media/police-car-light.gif" style="height:50px;" class="my-3">-->
<!--    <h6>Order failed!</h6>-->
<!--    <h6 style="font-weight: 600;">Reason: {{ resultMsg }}</h6>-->
<!--  </div>-->

    <div class="container">
      <div class="card desc-card tracker-card">
        <h5 class="card-title-uppercase mb-3">Product ordered</h5>
        <div class="d-flex">
          <span  v-for="item in orderDetails.orderItem" :key="item.id">
            <img :alt="item.productCode" :src=item.img style="height: 100px;border-radius: 8px;"/> &nbsp;
            </span>
        </div>
      </div>
    </div>

  <div class="container">
    <div class="card desc-card tracker-card">
      <h5 class="card-title-uppercase mb-3">Order & payment details</h5>
      <div class="d-flex justify-content-between mb-1">
        <h5 class="inactive-text">Order#</h5>
        <h5 class="active-text">{{ orderDetails.orderCode }}</h5>
      </div>
      <div class="d-flex justify-content-between mb-1">
        <h5 class="inactive-text">Order Status</h5>
        <h5 class="active-text">{{ orderDetails.orderStatusDisplayName }}</h5>
      </div>
<!--      <div class="d-flex justify-content-between mb-1">-->
<!--      <h5 class="inactive-text">Order Amount</h5>-->
<!--      <h5 class="active-text">Rs. {{ orderDetails.orderValue }}</h5>-->
<!--    </div>-->
<!--      <div class="d-flex justify-content-between mb-1">-->
<!--      <h5 class="inactive-text">Payment Method</h5>-->
<!--      <h5 class="active-text">{{ orderDetails.paymentMode }} - {{ orderDetails.prepaidPaymentMode }}</h5>-->
<!--    </div>-->
<!--      <div class="d-flex justify-content-between mb-1" v-if="orderDetails.paymentMode == 'COD'">-->
<!--        <h5 class="inactive-text">COD amount</h5>-->
<!--        <h5 class="active-text">Rs. {{ orderDetails.codAmount }}</h5>-->
<!--      </div>-->
    </div>
  </div>

  <nav v-show="resultCode == 1" class="navbar fixed-bottom navbar-light bg-light btn-nav">
    <button @click="getOrderDetails" type="submit" class="btn btn-primary w-100">Order Status</button>
  </nav>
  <nav v-show="!resultCode == 1" class="navbar fixed-bottom navbar-light bg-light btn-nav">
    <button @click="viewOrder(orderId)" type="submit" class="btn btn-primary w-100">View my orders</button>
  </nav>
  <nav v-show="resultCode == 1" class="navbar fixed-bottom navbar-light bg-light btn-nav">
    <button @click="viewOrder(orderId)" type="submit" class="btn btn-primary w-100">Retry</button>
  </nav>
  </div>
</template>

<script>
import router from '@/router'
import axios from 'axios'
import api from '@/storage/api'
import { getLoggedInUser } from '@/storage/auth.module'

export default {
  name: 'OrderConfirmation',
  props: {
    orderId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      orderDetails: [],
      orderStatus: '',
      resultCode: '',
      resultMsg: '',
      paymentStatus: '',
      utr: '',
      userId: getLoggedInUser().userId,
      userRole: getLoggedInUser().userRole,
      userToken: getLoggedInUser().userToken
    }
  },
  mounted () {
    // fetch(`http://localhost:8080/payment/verify/${this.orderId}`)
    this.getOrderDetails()
  },
  methods: {
    async getOrderDetails () {
      api.get(`/orders/search/${this.orderId}?uuid=leh4ejay`)
        .then(response => response.data)
        .then(data => {
          this.orderDetails = data
          this.loading = false
          this.orderStatus = data.orderStatus
          this.paymentStatus = data.paymentStatus
          this.utr = data.utr
          this.paymentMode = data.paymentMode
          // this.resultCode = data.body.resultInfo.resultCode
          // this.resultMsg = data.body.resultInfo.resultMsg
        })
        .catch(error => console.error(error))
    },
    viewOrder (orderId) {
      // router.push({
      //   name: 'home',
      //   params: { token: this.userToken }
      // })
      router.push({
        name: 'order'
      })
    }
  }
}
</script>

<style scoped>

</style>
